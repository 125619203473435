<template>
<div>
      <div class="row">
        <div class="col-lg-12">
          <form @submit.prevent="saveTemplate()" autocomplete="off">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="name">Template</label>
                  <select
                    class="custom-select"
                    name="template_name"
                    v-model="template.template"
                    @change="setTemplate(template.template)"
                    :class="{
                      'is-invalid': submitted && $v.template.namespace.$error
                    }"
                  >
                    <option value=""></option>
                  <option v-for="(p, i) in positus" :key=i :value="p">{{ p.name }}</option>
                  </select>
                  <div
                    v-if="submitted && !$v.template.namespace.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório.
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label for="language_code">Idioma</label>
                  <input
                    id="language_code"
                    v-model="template.language_code"
                    name="language_code"
                    type="text"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitted && $v.template.language_code.$error
                    }"
                  />
                  <div
                    v-if="submitted && !$v.template.language_code.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório.
                  </div>
                </div>
              </div>
              <div class="col-sm-2" style="margin-top:27px">
                <button type="submit" class="btn btn-primary btn-block">Salvar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-12">
          <div class="table-responsive">
            <table class="table table-centered table-nowrap table-hover">
              <thead>
                <tr>
                  <th width="80%">Template</th>
                  <th width="10%">Idioma</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="list in templates" :key="list.id">
                  <td>
                    <span class="badge font-size-14 badge-soft-primary" style="min-width:100px">{{ list.namespace }}</span>
                  </td>
                  <td>{{ list.language_code }}</td>
                  <td>
                    <button v-if="user.role === 'Admin'" type="button" @click="deleteTemplate(list)" class="link btn btn-outline-danger btn-sm"><i class="bx bx-trash"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
</template>
<script>
import Swal from 'sweetalert2'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
  },
  props: {
    idTemplate: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo template',
      loading: true,
      fullPage: true,
      submitted: false,
      template: {},
      positus: [],
      params: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      modal: false
    }
  },
  validations: {
    template: {
      name: { required },
      namespace: { required },
      language_code: { required }
    }
  },
  mounted () {
    this.getPositusTemplates()
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
  },
  methods: {
    ...mapActions('template', {
      getPositus: 'getPositus',
      getAll: 'getAll',
      getTemplate: 'show',
      delete: 'deleteTemplate',
      postData: 'save'
    }),
    deleteTemplate (template) {
      Swal.fire({
        title: 'Você tem certeza ?',
        html: `Será excluido o template: <b>${template.name}</b>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.delete(template.id)
            successMessage('Sucesso: Template excluído com sucesso.', 2500)
            this.updateList()
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    updateList () {
      this.getReport(this.params)
    },
    setTemplate (template) {
      if (template) {
        this.template.name = template.name
        this.template.namespace = template.name
        this.template.language_code = template.language.code
        this.template.components = template.components
        return
      }
      this.template = {}
    },
    getPositusTemplates () {
      let params = {
        search: null,
        status: 1,
        limit: 999,
        page: 1
      }
      this.getPositus(params).then((res) => {
        this.positus = res.data
      }).catch(error => {
        console.log(error)
      })
    },
    loadItem (id) {
      if (id) {
        this.title = 'Editar Template'
        this.getTemplate(id).then((data) => {
          this.template = this.data
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveTemplate () {
      this.submitted = true
      this.$v.template.$touch()
      if (this.$v.template.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.template).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.template = {}
        this.$v.$reset()
        this.updateList()
        this.loading = false
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.template = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('template', { data: 'template' }),
    ...mapState('template', ['templates', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
