<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form @submit.prevent="saveSetting()">
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <label for="name">Nome do Evento</label>
                  <input
                    id="name"
                    name="name"
                    v-model="setting.name"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.setting.name.$error,
                    }"
                    placeholder="Informe o nome do evento"
                  />
                  <div
                    v-if="submitted && !$v.setting.name.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório.
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-4">
                  <label for="type_event">Tipo de evento</label>
                  <select
                    class="custom-select"
                    name="type_event"
                    id="type_event"
                    v-model="setting.type_event"
                    :class="{
                      'is-invalid': submitted && $v.setting.type_event.$error,
                    }">
                      <option value=""></option>
                      <option value="1">Evento gratuito</option>
                      <option value="2">Evento pago</option>
                  </select>
                  <div
                    v-if="submitted && !$v.setting.type_event.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório.
                  </div>
                </div>
                <div class="col-sm-4">
                  <label for="event_init_date">Data início do evento</label>
                  <date-picker
                    v-model="setting.event_init_date"
                    type="datetime"
                    :first-day-of-week="1"
                    lang="pt-br"
                    format="DD/MM/YYYY HH:mm"
                    name="event_init_date"
                  >
                  </date-picker>
                </div>
                <div class="col-sm-4">
                  <label for="event_end_date">Data terminio do evento</label>
                  <date-picker
                    v-model="setting.event_end_date"
                    type="datetime"
                    :first-day-of-week="1"
                    lang="pt-br"
                    name="event_end_date"
                    format="DD/MM/YYYY HH:mm"
                  >
                  </date-picker>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-6">
                  <div style="display: flex;justify-content: space-between;">
                    <label for="address_complete">Endereço do evento</label>
                    <b-form-checkbox v-model="setting.address_undefined" switch>Não definido</b-form-checkbox>
                  </div>
                  <input
                    id="address_complete"
                    name="address_complete"
                    v-model="setting.address_complete"
                    type="text"
                    class="form-control"
                    placeholder="Informe o endereço completo do evento"
                  />
                </div>
                <div class="col-sm-6">
                  <div style="display: flex;justify-content: space-between;">
                  <label for="name_local">Nome do local</label>
                    <b-form-checkbox v-model="setting.local_undefined" switch>Não definido</b-form-checkbox>
                  </div>
                  <input
                    id="local_name"
                    name="local_name"
                    v-model="setting.local_name"
                    type="text"
                    class="form-control"
                    placeholder="Informe o nome do local do evento"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-12">
                  <label for="description">Descrição do Evento</label>
                  <ckeditor
                    :editor="editor"
                    v-model="setting.description"
                    :config="editorConfig"
                    :class="{
                      'is-invalid': submitted && $v.setting.description.$error,
                    }"
                  >
                  </ckeditor>
                  <div
                    v-if="submitted && !$v.setting.description.required"
                    class="invalid-feedback"
                  >
                  O campo é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Organização do evento</h4>
              <div class="row">
                <div class="col-sm-12">
                  <label for="organizer">Organizador</label>
                  <input
                    id="organizer"
                    name="organizer"
                    v-model="setting.organizer"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-6">
                  <label for="contact_email">E-mail de Suporte</label>
                  <input
                    id="contact_email"
                    name="contact_email"
                    v-model="setting.contact_email"
                    type="text"
                    class="form-control"
                    placeholder="Informe um e-mail para Suporte"
                  />
                </div>
                <div class="col-sm-6">
                  <label for="contact_phone">Telefone de Suporte</label>
                  <input
                    id="contact_phone"
                    name="contact_phone"
                    v-model="setting.contact_phone"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="float-right">
            <button type="buton" @click="cancelar()" class="btn btn-dark mr-4">
              Cancelar
            </button>
            <button type="submit" class="btn btn-primary mr-1">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </form>
  </Layout>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pt-br'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    ckeditor: CKEditor.component,
    Layout,
    DatePicker,
    PageHeader
  },
  name: 'setting-event',
  page: {
    title: 'Detalhes do evento',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      editor: ClassicEditor,
      editorConfig: { toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ] },
      loading: true,
      submitted: false,
      configEvent: false,
      setting: {},
      title: 'Detalhes do evento',
      items: [{
        text: 'Configurações gerais',
        href: '/'
      },
      {
        text: 'Detalhes do evento',
        active: true
      }
      ]
    }
  },
  validations: {
    setting: {
      name: { required },
      type_event: { required },
      description: { required }
    }
  },
  mounted () {
    let loader = this.$loading.show()
    this.GetSetting().then(() => {
      this.setting = this.data
      this.setting.event_init_date = this.$moment(this.setting.event_init_date, 'YYYY-MM-DD HH:mm').toDate()
      this.setting.event_end_date = this.$moment(this.setting.event_end_date, 'YYYY-MM-DD HH:mm').toDate()
      loader.hide()
    })
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('settingEvent', { data: 'setting' })
  },
  methods: {
    ...mapActions('settingEvent', {
      GetSetting: 'GetSetting',
      postSetting: 'SaveSetting'
    }),
    initSetting () {
      this.configEvent = true
    },
    async saveSetting () {
      this.submitted = true
      this.$v.setting.$touch()
      if (this.$v.setting.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      try {
        this.setting.event_init_date = this.$moment(this.setting.event_init_date).format('YYYY-MM-DD HH:mm:ss')
        this.setting.event_end_date = this.$moment(this.setting.event_end_date).format('YYYY-MM-DD HH:mm:ss')
        await this.postSetting(this.setting).then(() => {
          let message = 'Sucesso: Dados atualizados com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
        })
      } catch (err) {
        console.log(err)
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    }
  }
}
</script>
<style>
.nav-pills .nav-link { border-radius: 0 !important; }
.checkout-tabs .nav-pills .nav-link{
  margin-bottom: 0 !important;
  text-align: left !important;
}
.link { cursor: pointer; }
.preview-lg {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.logo-preview {
  width:200px;
  overflow:hidden;
  height: 65px;
}
.actions {
  margin-top: 1rem;
}
.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.bg-black { background-color: #afafaf; opacity: 1; }
.border-dashed {
  border: 2px dashed #ced4da !important;
  padding: 4px;
}
.box-qrcode {
  display: flex;
  padding: 5px 10px;
}
.label {
  padding: 0 !important;
  text-align: center;
  overflow: visible;
  outline: 1px dotted;
}
.qrcode {
  float:left;
  margin-left: 10px;
}
.number-qrcode {
  font-size: 18px;
  font-weight: bold;
  margin-top: -12px;
}
.info-label-default {
  text-align: left;
  margin-left: 20px;
  margin-top: 44px;
}
.info-label-model2 {
  display: block;
  text-align: left;
  margin-left: 20px;
  margin-top: 10px;
}
.name-participant-qrcode {
  display: block;
  line-height: 1.1em;
  font-size: 22px;
  font-weight: bold;
}
.name-participant-noqrcode {
  display: block;
  line-height: 1em;
  font-size: 22px;
  padding-top: 30px;
  font-weight: bold;
}
.text2-noqrcode {
  display: block;
  margin-bottom: -5px;
  font-size: 18px !important;
}
.text2-qrcode {
  display: block;
  font-size: 18px;
  margin-bottom: -5px;
  margin-top: 5px;
}
.text3-qrcode {
  display: block;
  margin-top: 6px;
  margin-bottom: -5px;
  font-size: 18px;
}
.text4-qrcode {
  display: block;
  margin-bottom: -5px;
  margin-top: 6px;
  font-size: 18px;
}
</style>
