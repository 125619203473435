<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Header />
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import Header from './Header'

export default {
  components: {
    Layout,
    PageHeader,
    Header
  },
  name: 'reports',
  page: {
    title: 'Relatórios',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      params: {},
      report: {},
      title: 'Relatórios',
      items: [{
        text: 'Relatórios',
        active: true
      }]
    }
  },
  mounted () {
    this.loading = false
    // this.showLoading()
  },
  methods: {
    loadReport () {
      this.$router.push({
        name: 'report-' + this.report.type,
        params: {
          params: this.report.type
        }
      })
      console.log(this.report)
      // this.$loading.show()
    }
  }
}
</script>
<style>
.link { cursor: pointer; }
.avatar-thumb { height: 4rem; width: 6rem;}
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
</style>
