<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="applyFilter()" autocomplete="off">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="group_id">Grupo</label>
                <select
                  class="custom-select"
                  name="group_id"
                  v-model="filter.group_id"
                >
                  <option value="">TODOS</option>
                  <option v-for="(g,idx) in groups" :key="idx" :value="g.id">{{ g.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="status">Status</label>
                <select
                  class="custom-select"
                  name="status"
                  v-model="filter.status"
                >
                  <option value="">TODOS</option>
                  <option value="99">Convidado</option>
                  <option value="0">Inscrito</option>
                </select>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="type_creator">Criação</label>
                <select
                  class="custom-select"
                  name="type_creator"
                  v-model="filter.type_creator"
                >
                  <option value="">TODOS</option>
                  <option value="1">MANUAL</option>
                  <option value="2">IMPORTADO</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Fechar</b-button>
      <b-button class="btn btn-dark" @click="clean()">Limpar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="applyFilter()">Aplicar</button>
    </template>
  </b-modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'guest-filter',
  components: {
  },
  props: {},
  data () {
    return {
      title: 'Filtro - Convidados',
      filter: {},
      modal: false
    }
  },
  methods: {
    ...mapActions('group', {
      getAllGroups: 'getAll'
    }),
    open () {
      this.getGroups()
      this.modal = true
    },
    applyFilter () {
      this.$emit('guestFiltered', this.filter)
      this.close()
    },
    close () {
      this.modal = false
    },
    clean () {
      this.filter = {}
    },
    getGroups () {
      let params = {
        search: this.search || null,
        status: 1,
        limit: 100,
        page: 1
      }
      this.params = params
      this.getAllGroups(this.params).then((res) => {
      }).catch(error => {
        console.log(error)
      })
    }
  },
  computed: {
    ...mapState('group', ['groups', 'pagination'])
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.form-check-label { padding-top: 2px !important }
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
