<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveSchedule()" autocomplete="off">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Informações da Programação</h4>
              <div class="row">
                <div class="col-sm-9">
                  <div class="form-group">
                    <label for="title">Título</label>
                    <input
                      id="title"
                      v-model="schedule.title"
                      name="title"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.schedule.title.$error,
                      }"
                    />
                    <div
                      v-if="submitted && !$v.schedule.title.required"
                      class="invalid-feedback"
                    >
                      O campo título é obrigatório.
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="name">Data</label>
                    <date-picker
                      v-model="schedule.schedule_date"
                      :first-day-of-week="1"
                      lang="pt-br"
                      format="DD/MM/YYYY"
                      :class="{
                        'is-invalid': submitted && $v.schedule.schedule_date.$error,
                      }"
                    >
                    </date-picker>
                    <div v-if="submitted && !$v.schedule.schedule_date.required" class="invalid-feedback">
                      O campo data é obrigatório.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="schedule_type">Tipo</label>
                    <multiselect
                    v-model="schedule.schedule_type"
                    :options="options" class="helo"
                    :max-height="180"
                    placeholder="Selecione uma opção"
                    :class="{
                      'is-invalid': submitted && $v.schedule.schedule_type.$error,
                    }"
                    :classError="$v.schedule.schedule_type.$error ? true : false"
                    >
                    </multiselect>
                    <div v-if="submitted && !$v.schedule.schedule_type.required" class="invalid-feedback">
                      O campo tipo é obrigatório.
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="name">Horário de Início</label>
                    <date-picker
                      v-model="schedule.start_time"
                      format="HH:mm"
                      value-type="format"
                      :showSecond="false"
                      type="time"
                      placeholder="HH:mm"
                      :class="{
                        'is-invalid': submitted && $v.schedule.start_time.$error,
                      }"
                    ></date-picker>
                    <div v-if="submitted && !$v.schedule.start_time.required" class="invalid-feedback">
                      O campo horário de início é obrigatório.
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="name">Horário de Término</label>
                    <date-picker
                      v-model="schedule.end_time"
                      format="HH:mm"
                      :showSecond="false"
                      value-type="format"
                      type="time"
                      placeholder="HH:mm"
                      :class="{
                        'is-invalid': submitted && $v.schedule.end_time.$error,
                      }"
                    ></date-picker>
                    <div v-if="submitted && !$v.schedule.end_time.required" class="invalid-feedback">
                      O campo horário de início é obrigatório.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="title">Descrição</label>
                    <textarea
                      class="form-control"
                      rows="3"
                      v-model="schedule.description"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="float-right">
                <button type="buton" @click="cancelar()" class="btn btn-dark mr-4">
                  Cancelar
                </button>
                <button type="submit" class="btn btn-primary mr-1">
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import Moment from 'moment'
import 'vue2-datepicker/locale/pt-br'
import Multiselect from 'vue-multiselect'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
export default {
  components: {
    Multiselect,
    DatePicker,
    Layout,
    PageHeader
  },
  name: 'Programação',
  page: {
    title: 'Programação',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      autoClose: '',
      schedule: {
        id: '',
        title: '',
        description: '',
        schedule_date: '',
        schedule_type: '',
        start_time: '',
        end_time: ''
      },
      submitted: false,
      options: [
        'Premiação',
        'Abertura',
        'Almoço ',
        'Apresentação ',
        'Apresentação Artística',
        'Atividade Prática',
        'Aula',
        'Coffee break',
        'Competição',
        'Conferência ',
        'Coordenação da atividade',
        'Credenciamento ',
        'Curso',
        'Encerramento ',
        'Encontro com especialista',
        'Estudo de Caso',
        'Exposição',
        'Feira',
        'Festa',
        'Fórum',
        'Imersão',
        'Intervalo',
        'Jantar ',
        'Mesa Temática',
        'Mesa-redonda',
        'Oficina',
        'Painel',
        'Palestra',
        'Palestra Sessão Internacional',
        'Palestra Sessão Nacional',
        'Relatos de Experiência',
        'Roda de Conversa',
        'Seminário',
        'Sessão de Perguntas',
        'Sessão de Vídeos ',
        'Sessão Solene',
        'Sessão técnica',
        'Visita técnica',
        'Webinário',
        'Workshop'
      ],
      title: 'Programação',
      items: [{
        text: 'Programação',
        href: '/schedules'
      },
      {
        text: 'Novo Programação',
        active: true
      }
      ]
    }
  },
  validations: {
    schedule: {
      title: { required },
      schedule_date: { required },
      schedule_type: { required },
      start_time: { required },
      end_time: { required }
    }
  },
  mounted () {
    this.loadItem()
  },
  computed: {
    ...mapState('schedules', { data: 'schedule' })
  },
  methods: {
    ...mapActions('schedules', {
      fetch: 'GetSchedule',
      postData: 'SaveSchedule'
    }),
    cancelar () {
      this.$router.push({ name: 'site-schedules' })
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.items[1].text = 'Editar Programação'
        this.fetch(this.$route.params.id)
          .then(() => {
            this.schedule = this.data
            this.schedule.schedule_date = Moment(this.schedule.schedule_date, 'YYYY-MM-DD').toDate()
          }).then(() => {
            loader.hide()
          })
      }
    },
    async saveSchedule () {
      this.submitted = true
      this.$v.schedule.$touch()
      if (this.$v.schedule.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      this.schedule.schedule_date = this.$moment(this.schedule.schedule_date, 'YYYY-MM-DD').format('YYYY-MM-DD')

      window.scroll(0, 0)
      await this.postData(this.schedule).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.cancelar()
        loader.hide()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    }
  }
}
</script>
<style scoped>
.list-speakers {padding:5px}
.link { cursor:pointer !important}
.border-light {
  padding: 4px;
  display: flex;
  background: #f1f1f1;
  border-radius: 0.25rem;
}
.dropzone { padding: 5px; max-height: 150px; max-width: 150px;}
.mx-datepicker.is-invalid {
  border: 1px solid #f86c6b !important;
  border-radius: 4px !important
}
</style>
