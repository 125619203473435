<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="table-responsive">
          <table class="table project-list-table table-nowrap table-centered table-borderless">
            <tr class="link p5">
              <td width="98%" @click="openURL('site-schedules')">
                <div style="display: flex;gap:20px;justify-content: flex-start;padding: 10px;">
                <i class="far fa-calendar-alt fa-2x" size="md"></i>
                <h5 class="text-truncate mt-1 text-dark">
                  Programação
                </h5>
                </div>
              </td>
              <td width="20px"><i class="fas fa-angle-right fa-2x" size="md"></i></td>
            </tr>
            <tr class="link p5">
              <td width="98%" @click="openURL('site-speakers')">
                <div style="display: flex;gap:20px;justify-content: flex-start;padding: 10px;">
                <i class="fas fa-users fa-2x" size="md"></i>
                <h5 class="text-truncate mt-1 text-dark">
                 Palestrantes
                </h5>
                </div>
              </td>
              <td width="20px"><i class="fas fa-angle-right fa-2x" size="md"></i></td>
            </tr>
            <tr class="link p5">
              <td width="98%" @click="openURL('site-sponsors')">
                <div style="display: flex;gap:20px;justify-content: flex-start;padding: 10px;">
                <i class="fas fa-handshake fa-2x" size="md"></i>
                <h5 class="text-truncate mt-1">
                  <a href="javascript: void(0);" class="text-dark">Patrocinadores</a>
                </h5>
                </div>
              </td>
              <td width="20px"><i class="fas fa-angle-right fa-2x" size="md"></i></td>
            </tr>
            <tr class="link p5">
              <td width="98%">
                <div style="display: flex;gap:20px;justify-content: flex-start;padding: 10px;">
                <i class="fab fa-whatsapp fa-2x" size="md"></i>
                <h5 class="text-truncate mt-1">
                  <a href="javascript: void(0);" class="text-dark">Suporte WhatsApp vísivel</a>
                </h5>
                </div>
              </td>
              <td width="20px"><b-form-checkbox v-model="setting.support_whatsapp" switch style="margin-right: 10px" @change="updateSupport()"></b-form-checkbox></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader
  },
  name: 'setting-event',
  page: {
    title: 'Site do evento',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      submitted: false,
      setting: {},
      title: 'Site do evento',
      items: [{
        text: 'Configurações gerais',
        href: '/'
      },
      {
        text: 'Site do evento',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.GetSetting().then(() => {
      this.setting = this.data
    })
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('settingEvent', { data: 'setting' })
  },
  methods: {
    ...mapActions('settingEvent', {
      GetSetting: 'GetSetting',
      postSetting: 'SaveSetting'
    }),
    async updateSupport () {
      let loader = this.$loading.show()
      try {
        await this.postSetting({ id: this.setting.id, support_whatsapp: this.setting.support_whatsapp }).then(() => {
          let message = 'Sucesso: Dados atualizados com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
        })
      } catch (err) {
        console.log(err)
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    openURL (url) {
      this.$router.push({
        name: url
      })
    }
    // ...mapActions('settingEvent', {
    //   GetSetting: 'GetSetting',
    //   postSetting: 'SaveSetting'
    // }
    // ),
  }
}
</script>
<style>
</style>
