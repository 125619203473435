<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-6">
                <div class="search-box mr-2 mb-2 d-inline-block ">
                  <div class="position-relative">
                    <input type="text" class="form-control" v-model="search" placeholder="Digite e pressione 'ENTER'" @keyup.enter="doSearch"/>
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right" v-if="user.role === 'Admin'">
                    <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newSeat()">
                      <i class="mdi mdi-plus mr-1"></i> Novo Ambiente
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-hover table-nowrap">
                <thead>
                  <tr>
                    <th width="50%">Ambiente</th>
                    <th width="20%">Estatítsticas</th>
                    <th width="15%">Ultima Edição</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in seats" :key="list.id">
                    <td class="link font-size-14" @click="detailSeat(list.id)">{{ list.title }}</td>
                    <td class="link" @click="detailSeat(list.id)">
                      <div class="flex items-center">
                        <div
                          class="flex items-center justify-center p-2 rounded text-teal bg-teal-light"
                        >
                          <svg class="h-6 w-6 svg-icon" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><desc>seating chair</desc><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M1.5 4.88L3 13.12M1.5 19.125l1.5-6h3-.01c.82-.01 1.5.67 1.5 1.5v4.5M22.5 4.88L21 13.12M22.5 19.125l-1.5-6h-3.01c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v4.5M12 8.62v10.5M6.75 8.62h10.5" pid="0"/></g></svg>
                        </div>
                        <div class="flex flex-col ml-4 text-sm">
                          <p class="font-semibold m-0">{{ list.stats_counts.seatingAreas }}</p>
                          <p class="m-0">Mesas</p>
                        </div>
                        <div
                          class="flex items-center justify-center p-2 ml-8 rounded text-green bg-green-light"
                        >
                          <svg class="h-6 w-6 svg-icon" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M10.782 10.516h0a6.025 6.025 0 00-4.04-1.52h0a5.987 5.987 0 00-6 5.24M6.75.75a3.375 3.375 0 100 6.75 3.375 3.375 0 100-6.75zM16.5.75a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM20.4 9h0c-1.25-2.16-4-2.89-6.15-1.65-.43.24-.82.56-1.14.93M17.25 11.24a6 6 0 100 12 6 6 0 100-12z" pid="0"/><path d="M19.924 15.5l-2.91 3.87h-.01a.76.76 0 01-1.06.14c-.03-.03-.06-.05-.08-.07l-1.5-1.5" pid="1"/></g></svg>
                        </div>
                        <div class="flex flex-col ml-4 text-sm">
                          <p class="font-semibold m-0">{{ list.stats_counts.occupiedSeats + ' de ' + list.stats_counts.totalSeats }}</p>
                          <p class="m-0">Sentado(a)</p>
                        </div>
                      </div>
                    </td>
                    <td class="link" @click="detailSeat(list.id)">{{ list.atualizacao ? list.atualizacao : ''}}</td>
                    <td style="display: flex; gap: 4px;">
                      <button v-if="user.role === 'Admin' && (list.status === 'pending' || list.status === 'scheduled')" type="button" @click="deleteSeat(list)" class="link btn btn-outline-danger btn-sm"><i class="bx bx-trash"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" :idSeating="idSeating" ref="formSeat" @created="updateList"></modal-form>
    <modal-seats v-model="modalSeats" :idSeating="idSeating" ref="detailSeat" @created="updateList"></modal-seats>
  </Layout>
</template>
<script>
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import ModalForm from './Form'
import ModalSeats from './ModalSeats'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader,
    ModalForm,
    ModalSeats
  },
  name: 'seating',
  page: {
    title: 'Seating',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      search: '',
      exampleTemplate: false,
      logMessage: {
        body: null
      },
      timeout: null,
      modalForm: false,
      modalSeats: false,
      modalTemplate: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      idSeating: null,
      perPage: 20,
      submitted: false,
      title: 'Seating',
      items: [{
        text: 'Seating'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('seating', ['seats', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('seating', {
      getAll: 'getAll',
      delete: 'deleteSeat',
      changeStatus: 'changeStatus'
    }),
    newSeat () {
      this.$refs.formSeat.open('')
      this.modalForm = true
    },
    detailSeat (id) {
      this.$refs.detailSeat.open(id)
      this.modalSeats = true
    },
    deleteSeat (seat) {
      Swal.fire({
        title: 'Você tem certeza ?',
        html: `Será excluido o seating: <br /><b>${seat.title}</b>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.delete(seat.id)
            successMessage('Sucesso: Seating excluído com sucesso.', 2500)
            this.updateList()
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
.progress {
  background-color: #d9d9d9 !important;
}
</style>
