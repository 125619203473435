<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveINEP()" autocomplete="off">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="name">Nome da Escola</label>
                <input
                  id="name"
                  v-model="inep.name"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.inep.name.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.inep.name.required"
                  class="invalid-feedback"
                >
                  O campo nome é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="inep">INEP</label>
                <input
                  id="inep"
                  v-model="inep.inep"
                  name="name"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="idarco">ID ARCO</label>
                <input
                  id="idarco"
                  v-model="inep.idarco"
                  name="name"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group">
                <label for="prioridade">Prioridade</label>
                <select
                  id="prioridade"
                  v-model="inep.prioridade"
                  name="prioridade"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.inep.prioridade.$error
                  }"
                >
                  <option value=""></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
                <div
                  v-if="submitted && !$v.inep.prioridade.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="inep">Limite por cadastro</label>
                <input
                  id="limit_register"
                  v-model="inep.limit_register"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.inep.limit_register.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.inep.limit_register.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveINEP()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading
  },
  props: {
    idINEP: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo INEP',
      loading: true,
      fullPage: true,
      submitted: false,
      inep: {},
      modal: false
    }
  },
  validations: {
    inep: {
      name: { required },
      limit_register: { required },
      prioridade: { required }
    }
  },
  methods: {
    ...mapActions('inep', {
      getINEP: 'show',
      postData: 'save'
    }),
    loadItem (id) {
      if (id) {
        this.title = 'Editar INEP'
        this.getINEP(id).then((data) => {
          this.inep = this.data
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveINEP () {
      this.submitted = true
      this.$v.inep.$touch()
      if (this.$v.inep.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.inep).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.inep = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('inep', { data: 'inep' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
