<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveSeating()" autocomplete="off">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="title">Nome do ambiente</label>
                <input
                  id="title"
                  v-model="seating.title"
                  name="title"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.seating.title.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.seating.title.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="groups">Grupo</label>
                <multiselect tag-placeholder="Selecione uma ou mais grupos" placeholder="Selecione uma ou mais grupos" v-model="seating.groups" label="name" track-by="id" :options="groups" :multiple="true"></multiselect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="status">Status</label>
                <multiselect tag-placeholder="Selecione uma ou mais status" placeholder="Selecione uma ou mais status" v-model="seating.status" :options="statusSelect" track-by="id" label="name" :multiple="true"></multiselect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="groups">Tags</label>
                <multiselect tag-placeholder="Selecione ma ou mais tags" placeholder="Selecione uma ou mais tags" v-model="seating.tags" label="name" track-by="id" :options="tags" :multiple="true"></multiselect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="tables">Número de mesas</label>
                <input
                  id="tables"
                  v-model="seating.tables"
                  name="tables"
                  type="number"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.seating.tables.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.seating.tables.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="number_of_initial_seats">Assentos por Mesa</label>
                <input
                  id="number_of_initial_seats"
                  v-model="seating.number_of_initial_seats"
                  name="number_of_initial_seats"
                  type="number"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.seating.number_of_initial_seats.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.seating.number_of_initial_seats.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveSeating()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Loading,
    Multiselect
  },
  props: {
    idseating: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo Seating - Ambiente',
      loading: true,
      fullPage: true,
      submitted: false,
      seating: {
        number_of_initial_seats: 6,
        tables: 10,
        groups: [],
        tags: [],
        status: []
      },
      modal: false
    }
  },
  validations: {
    seating: {
      title: { required },
      tables: { required },
      number_of_initial_seats: { required }
    }
  },
  methods: {
    ...mapActions('settings', { GetStatus: 'GetStatusSelect' }),
    ...mapActions('seating', {
      postData: 'save'
    }),
    ...mapActions('tag', {
      getAll: 'getAll'
    }),
    ...mapActions('group', {
      getAllGroups: 'getAll'
    }),
    setFilterseating () {
      this.seating.groups = []
      this.seating.status = []
      this.seating.tags = []
    },
    getTags (filter) {
      let params = {
        search: this.search || null,
        limit: 100,
        page: 1
      }
      this.params = params
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
      }).catch(error => {
        console.log(error)
      })
    },
    getGroups () {
      let params = {
        search: this.search || null,
        status: 1,
        limit: 100,
        page: 1
      }
      this.params = params
      this.getAllGroups(this.params).then((res) => {
      }).catch(error => {
        console.log(error)
      })
    },
    open () {
      this.getTags()
      this.getGroups()
      this.GetStatus()
      this.modal = true
      this.loading = false
    },
    async saveSeating () {
      this.submitted = true
      this.$v.seating.$touch()
      if (this.$v.seating.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.seating).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.seating = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('settings', ['statusSelect']),
    ...mapState('seating', { data: 'seat' }),
    ...mapState('tag', ['tags']),
    ...mapState('group', ['groups'])
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
