var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveWA()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('label',{attrs:{"for":"whatsapp"}},[_vm._v("Whatsapp")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.wa.whatsapp),expression:"wa.whatsapp"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.wa.whatsapp.$error,
                  },attrs:{"name":"whatsapp","id":"whatsapp"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.wa, "whatsapp", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}}),_c('option',{attrs:{"value":"1"}},[_vm._v("Ativo")]),_c('option',{attrs:{"value":"0"}},[_vm._v("Desativado")])]),(_vm.submitted && !_vm.$v.wa.whatsapp.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()])]),(parseInt(_vm.wa.whatsapp) === 1)?_c('span',[_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-sm-6"},[_c('label',{attrs:{"for":"key_workspace"}},[_vm._v("Chave Workspace")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.wa.key_workspace),expression:"wa.key_workspace"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitted && _vm.$v.wa.key_workspace.$error,
                    },attrs:{"id":"key_workspace","name":"key_workspace","type":"text"},domProps:{"value":(_vm.wa.key_workspace)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.wa, "key_workspace", $event.target.value)}}}),(_vm.submitted && !_vm.$v.wa.key_workspace.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()]),_c('div',{staticClass:"col-sm-6"},[_c('label',{attrs:{"for":"key_number"}},[_vm._v("Chave Número")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.wa.key_number),expression:"wa.key_number"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitted && _vm.$v.wa.key_number.$error,
                    },attrs:{"id":"key_number","name":"key_number","type":"text"},domProps:{"value":(_vm.wa.key_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.wa, "key_number", $event.target.value)}}}),(_vm.submitted && !_vm.$v.wa.key_number.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-sm-12"},[_c('label',{attrs:{"for":"token_positus"}},[_vm._v("Token Positus")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.wa.token_positus),expression:"wa.token_positus"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitted && _vm.$v.wa.token_positus.$error,
                    },attrs:{"id":"token_positus","name":"token_positus","rows":"5"},domProps:{"value":(_vm.wa.token_positus)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.wa, "token_positus", $event.target.value)}}}),_vm._v(" "),(_vm.submitted && !_vm.$v.wa.token_positus.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()])])]):_vm._e(),_c('div',{staticClass:"row mt-3 mb-2"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v(" Salvar ")])])])])])])])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-tabs',{attrs:{"nav-class":"nav-tabs-custom","content-class":"p-3 text-muted","lazy":""}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"d-inline-block d-sm-none"},[_c('i',{staticClass:"fas fa-home"})]),_c('span',{staticClass:"d-none d-sm-inline-block"},[_vm._v("Templates")])]},proxy:true}])},[_c('WATemplates')],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"d-inline-block d-sm-none"},[_c('i',{staticClass:"far fa-envelope"})]),_c('span',{staticClass:"d-none d-sm-inline-block"},[_vm._v("Configurações de status")])]},proxy:true}])},[_c('WAStatusTemplates')],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }