<template>
  <b-modal
    v-model="modal"
    :hide-footer="true"
    :no-enforce-focus="true"
    :no-close-on-backdrop="true"
    id="full-screen-modal"
  >
  <template #modal-header="{ close }">
    <div class="flex w-full">
      <div class="flex-1 font-semibold text-xl text-gray-800">
          <div class="flex items-center">
            <div class="shrink-0 mr-4">
              <svg version="1.1" viewBox="0 0 24 24" class="w-5 h-5 svg-icon"><desc>pencil write</desc><g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round"><path pid="0" d="M22.19 1.81h0a3.636 3.636 0 00-5.15.01c-.01 0-.02.01-.03.02l-14.5 14.5-1.78 6.9 6.9-1.78 14.5-14.5h-.01a3.655 3.655 0 00.06-5.15l-.03-.03zM16.61 2.26l5.13 5.13M2.52 16.34l5.14 5.13"></path></g></svg>
            </div>
            <span v-if="!openInput" v-b-tooltip.hover :title="`Click para editar o nome de ${seat.title}`" class="border-b border-gray-500 p-2 truncate link" @click="editTitle()">
              {{ seat.title }}
            </span>
            <div class="form-field-wrapper" v-if="openInput">
              <input
                id="name"
                v-model="seat.title"
                name="name"
                type="text"
                class="form-control"
                v-on:keyup.enter="updateTitle"
              />
            </div>
          </div>
      </div>
      <div class="flex-1 font-semibold text-xl text-gray-800">
        <div class="flex items-center justify-end mr-4 flex-1 hidden text-sm">
          <div class="flex items-center justify-center p-2 rounded text-teal bg-teal-light">
            <svg class="h-6 w-6 svg-icon" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><desc>seating chair</desc><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M1.5 4.88L3 13.12M1.5 19.125l1.5-6h3-.01c.82-.01 1.5.67 1.5 1.5v4.5M22.5 4.88L21 13.12M22.5 19.125l-1.5-6h-3.01c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v4.5M12 8.62v10.5M6.75 8.62h10.5" pid="0"/></g></svg>
          </div>
          <div class="flex flex-col ml-4 text-sm">
            <p class="font-semibold m-0">{{ seat.stats_counts ? seat.stats_counts.seatingAreas : '-' }}</p>
            <p class="m-0">Mesas</p>
          </div>
          <div class="flex items-center justify-center p-2 ml-8 rounded text-green bg-green-light">
            <svg class="h-6 w-6 svg-icon" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M10.782 10.516h0a6.025 6.025 0 00-4.04-1.52h0a5.987 5.987 0 00-6 5.24M6.75.75a3.375 3.375 0 100 6.75 3.375 3.375 0 100-6.75zM16.5.75a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM20.4 9h0c-1.25-2.16-4-2.89-6.15-1.65-.43.24-.82.56-1.14.93M17.25 11.24a6 6 0 100 12 6 6 0 100-12z" pid="0"/><path d="M19.924 15.5l-2.91 3.87h-.01a.76.76 0 01-1.06.14c-.03-.03-.06-.05-.08-.07l-1.5-1.5" pid="1"/></g></svg>
          </div>
          <div class="flex flex-col ml-4 text-sm">
            <p class="font-semibold m-0">{{ seat.stats_counts ? seat.stats_counts.occupiedSeats : '-' }} de {{ seat.stats_counts ? seat.stats_counts.totalSeats : '-' }}</p>
            <p class="m-0">Sentado(a)</p>
          </div>
        </div>
      </div>
      <div class="grow-0">
        <b-button size="sm" variant="outline-dark" @click="close()">X</b-button>
      </div>
    </div>
  </template>
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :cancel-disabled="false"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="seat-content">
      <div class="d-lg-flex">
        <div class="chat-leftsidebar mr-lg-4">
          <div class="">
            <div class="search-box py-2">
              <div class="position-relative">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Pesquisar participantes não sentados..."
                />
                <i class="bx bx-search-alt search-icon"></i>
              </div>
            </div>
            <h4 class="card-title mb-4 mt-4">Participantes</h4>
            <div class="chat-leftsidebar-nav">
              <div class="tab-content py-4">
                  <div class="max-h-screen-64 overflow-y-auto select-none">
                    <div class="mb-2">
                      <div>
                        <div
                          class="bg-white border-l-2 border-gray-500 text-gray-600 hover:border-purple hover:bg-gray-100 seatable-draggable-handle cursor-move"
                          draggable="false"
                        >
                          <div v-for="p in participantsSeat" :key="p.id">
                            <div
                              class="flex items-center space-x-3 p-3 border-b border-gray-100 droppable"
                            >
                              <div class="w-full seatable">
                              {{ p.name }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 user-chat">
          <div class="flex w-full">
            <div class="relative">
              <div class="search-box">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Pesquisar por mesas."
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div class="flex flex-1 justify-end space-x-2">
              <button class="btn btn-sm btn-outline-dark">
                <i class="mdi mdi-arrow-expand"></i> Expandir
              </button>
              <button class="btn btn-sm btn-success ml-1">
                <i class="bx bx-plus-circle"></i> Nova Mesa
              </button>
            </div>
          </div>
          <div class="drag-container">
            <div class="row drag-list">
              <div class="col-lg-4 col-md-12 col-sm-12 drag-column" v-for="table in seat.areas.data" :key="table.id">
                <div class="table-card mt-2">
                  <div class="table-header">
                    <b-card-header header-tag="header" class="table-header-card" role="tab">
                      <h6 class="m-0">
                        <div class="btn-group">
                          <b-dropdown variant="white">
                            <template v-slot:button-content>
                              <i class="bx bx-dots-vertical"></i>
                            </template>
                            <b-dropdown-item class="table-actions">
                              <i class="bx bxs-edit"></i>
                              Editar Mesa
                            </b-dropdown-item>
                            <b-dropdown-item class="table-actions delete">
                              <i class="bx bxs-trash"></i>
                              Deletar Mesa
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                        {{ table.name }}
                        <a
                          v-b-toggle="'accordion-' + table.id"
                          class="text-dark float-right m-2"
                          href="javascript: void(0);"
                        >
                        <span class="when-opened">
                          <i class="fas fa-angle-down"></i>
                        </span>
                        <span class="when-closed">
                          <i class="fas fa-angle-up"></i>
                        </span>
                        </a>
                      </h6>
                    </b-card-header>
                    <b-collapse :id="`accordion-${table.id}`" visible :accordion="`table-${table.id}`" role="tabpanel">
                      <div class="seat-participant py-2 pl-2 select-none" v-for="(seat, idx) in table.number_of_seats" :key="idx">
                        <div data-position="1" class="">
                          <div
                            class="flex items-center text-gray-600 cursor-move seat-taken"
                          >
                            <div class="flex flex-1 break-words mr-2 seatable">
                              <div class="clone-hide mr-4">{{ idx + 1 }}.</div>
                              <div class="flex flex-col justify-center">
                                <p></p>
                              </div>
                            </div>
                            <div class="flex items-center ml-2 clone-hide">
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
// Import component
// import draggable from 'vuedraggable'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { errorMessage, successMessage } from '@/components/alertsMessages'
// import test from './test.vue'

export default {
  components: {
    // draggable,
    Loading
    // SidebarSeat
  },
  props: {
    idseating: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo Seating - Ambiente',
      loading: true,
      newTitle: null,
      openInput: false,
      fullPage: true,
      submitted: false,
      seat: {},
      modal: false
    }
  },
  methods: {
    ...mapActions('settings', { GetStatus: 'GetStatusSelect' }),
    ...mapActions('seating', {
      getShow: 'show',
      saveSeat: 'save',
      getParticipants: 'getAllAParticipants'
    }),
    ...mapActions('tag', {
      getAll: 'getAll'
    }),
    ...mapActions('group', {
      getAllGroups: 'getAll'
    }),
    editTitle () {
      this.openInput = !this.openInput
    },
    async updateTitle () {
      this.openInput = !this.openInput
      if (!this.seat.title) {
        errorMessage('Nome do ambiente é obrigatório')
      }
      this.loading = true
      console.log('call here')
      await this.saveSeat({ id: this.seat.id, title: this.seat.title }).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.loading = false
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    setFilterseating () {
      this.seating.groups = []
      this.seating.status = []
      this.seating.tags = []
    },
    getListParticipants () {
      let params = {
        search: this.search || null,
        limit: 20,
        page: 1
      }
      this.params = params
      this.getParticipants(this.params).then((res) => {
        // this.participants = res.data
      }).catch(error => {
        console.log(error)
      })
    },
    getTags (filter) {
      let params = {
        search: this.search || null,
        limit: 100,
        page: 1
      }
      this.params = params
      this.getAll(this.params).then((res) => {
      }).catch(error => {
        console.log(error)
      })
    },
    getGroups () {
      let params = {
        search: this.search || null,
        status: 1,
        limit: 100,
        page: 1
      }
      this.params = params
      this.getAllGroups(this.params).then((res) => {
      }).catch(error => {
        console.log(error)
      })
    },
    loadItem (id) {
      if (id) {
        this.getShow(id).then((data) => {
          this.seat = this.data
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id) {
      this.getTags()
      this.getGroups()
      this.GetStatus()
      this.getListParticipants()
      this.loading = true
      if (id) {
        this.loadItem(id)
      }
      this.modal = true
      this.loading = false
    },
    async saveSeating () {
      this.submitted = true
      this.$v.seating.$touch()
      if (this.$v.seating.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.seating).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.seat = {}
      this.$emit('created')
    }
  },
  computed: {
    ...mapState('settings', ['statusSelect']),
    ...mapState('seating', { data: 'seat' }),
    ...mapState('seating', ['participantsSeat', 'paginationP']),
    ...mapState('tag', ['tags']),
    ...mapState('group', ['groups'])
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }
::v-deep .dropdown-menu {
  padding: 0 !important;
}
::v-deep .table-actions > .dropdown-item {
  padding: 0.55rem 0.75rem;
}
::v-deep .delete > .dropdown-item:hover, .dropdown-item:focus {
    color: rgb(221, 7, 7);
  }
.table-header-card {
    padding: 0.75rem 0.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border: 2px solid #f0f0f0;
}
::v-deep .btn-group > .btn-white {
  padding: 0.30rem 0.25rem !important;
}
.seat-participant {
  background: #fff;
}
.seat-participant:hover {
  background: #f7f7f7;
  cursor: move;
}
.seat-content {
  background: #fff;
}
    ::v-deep #full-screen-modal > .modal-dialog {
      max-width: 100%;
      height: 100%;
      bottom: 0;
      margin: 0;
    }
    ::v-deep #full-screen-modal > .modal-dialog > .modal-content {
      height: 100%;
      border-radius: 0;
    }
    ::v-deep #full-screen-modal > .modal-dialog > .modal-content > .modal-header {
      background: #f7f7f7;
    }
   ::v-deep #full-screen-modal > .modal-dialog > .modal-content > .modal-header > .close {
      font-size:  2.21875rem;
      font-weight: normal;
    }
</style>
