<template>
  <div>
    <div class="row  mt-3 mb-2">
      <div class="col-sm-12">
        <div class="float-sm-end d-flex" style="justify-content: end">
          <div class="me-2">
              <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newStatusTemplate()">
                <i class="mdi mdi-plus mr-1"></i> Novo
              </button>
            </div>
        </div>
      </div>
      <!-- end col-->
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="table-responsive">
          <table class="table table-centered table-hover">
            <thead>
              <tr>
                <th width="60%">Status</th>
                <th width="30%">template</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="list in statusTemplates" :key="list.id">
                <td>
                  {{ list.name }}
                </td>
                <td>{{ list.template ? list.template.data.name : 'Error - Sem template' }}</td>
                <td>
                  <button v-if="user.role === 'Admin'" type="button" @click="deleteStatusTemplate(list)" class="link btn btn-outline-danger btn-sm"><i class="bx bx-trash"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" ref="formStatus" @created="updateList"></modal-form>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import { mapActions, mapState } from 'vuex'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import ModalForm from './Form'

export default {
  components: {
    ModalForm
  },
  props: {
    idTemplate: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Configurações de status',
      loading: true,
      fullPage: true,
      submitted: false,
      modalForm: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 5
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
  },
  methods: {
    ...mapActions('statusTemplate', {
      getAll: 'getAll',
      delete: 'deleteStatusTemplate'
    }),
    editStatusTemplate (id) {
      this.$refs.formStatus.open(id)
      this.modalForm = true
    },
    newStatusTemplate () {
      this.$refs.formStatus.open('')
      this.modalForm = true
    },
    deleteStatusTemplate (status) {
      Swal.fire({
        title: 'Você tem certeza ?',
        html: `Será excluido a Parametrização de Status: <br /><b>${status.name}</b>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.delete(status.id)
            successMessage('Sucesso: Parametrização de status excluída com sucesso.', 2500)
            this.updateList()
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {
        include: 'template',
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    updateList () {
      this.getReport(this.params)
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('statusTemplate', ['statusTemplates', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
