<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-6">
                <div class="search-box mr-2 mb-2 d-inline-block ">
                  <div class="position-relative">
                    <input type="text" class="form-control" v-model="search" placeholder="Digite e pressione 'ENTER'" @keyup.enter="doSearch"/>
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right" v-if="user.role === 'Admin'">
                    <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="netINEP()">
                      <i class="mdi mdi-plus mr-1"></i> Novo INEP
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th width="50%">Escola</th>
                    <th width="10%">INEP</th>
                    <th width="10%">ID ARCO</th>
                    <th width="10%">Prioridade</th>
                    <th width="15%">Limite por cadastro</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in ineps" :key="list.id">
                    <td class="link" @click="editINEP(list.id)">
                      <span class="badge font-size-14 badge-soft-primary" style="min-width:100px">{{ list.name }}</span>
                    </td>
                    <td class="link" @click="editINEP(list.id)">
                      {{ list.inep }}
                    </td>
                    <td class="link" @click="editINEP(list.id)">
                      {{ list.idarco }}
                    </td>
                    <td class="link" @click="editINEP(list.id)">
                      <span class="badge font-size-12" :class="{'badge-soft-success': list.prioridade === 1, 'badge-soft-danger': list.prioridade === 2}" style="font-weight: 400; white-space: nowrap;text-overflow: ellipsis;max-width: 260px;overflow: hidden;">
                        {{ 'Prioridade ' + list.prioridade }}
                      </span>
                    </td>
                    <td class="link" @click="editINEP(list.id)">
                      {{ list.limit_register }}
                    </td>
                    <td><button v-if="user.role === 'Admin'" type="button" @click="deleteINEP(list)" class="link btn btn-outline-danger btn-sm"><i class="bx bx-trash"></i></button></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" :idINEP="idINEP" ref="formINEP" @created="updateList"></modal-form>
  </Layout>
</template>
<script>
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import ModalForm from './Form'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader,
    ModalForm
  },
  name: 'INEP',
  page: {
    title: 'INEP',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      search: '',
      timeout: null,
      modalForm: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      idINEP: null,
      perPage: 20,
      statusLabel: ['Inativo', 'Ativo'],
      submitted: false,
      title: 'INEP',
      items: [{
        text: 'INEP'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('inep', ['ineps', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('inep', {
      getAll: 'getAll',
      delete: 'deleteINEP'
    }),
    netINEP () {
      this.$refs.formINEP.open('')
      this.modalForm = true
    },
    editINEP (id) {
      if (this.user.role === 'Admin') {
        this.$refs.formINEP.open(id)
        this.modalForm = true
        this.idINEP = id
      }
    },
    deleteINEP (tag) {
      Swal.fire({
        title: 'Você tem certeza ?',
        html: `Será excluido o inep: <b>${tag.name}</b>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.delete(tag.id)
            successMessage('Sucesso: INEP excluído com sucesso.', 2500)
            this.updateList()
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
</style>
