<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveTicket()" autocomplete="off">
          <div class="row">
            <div class="col-sm-8">
              <div class="form-group">
                <label for="name">Nome do ingresso</label>
                <input
                  id="name"
                  v-model="ticket.name"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.ticket.name.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.ticket.name.required"
                  class="invalid-feedback"
                >
                  O campo nome é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="amount_ticket">Valor do Ingresso</label>
                <money
                  class="form-control text-right"
                  v-model="price"
                  v-bind="money"
                  :class="{
                    'is-invalid': submitted && $v.ticket.amount_ticket.$error
                  }"></money>
                <!-- <input
                  id="amount"
                  v-money="money"
                  v-model="ticket.amount"
                  name="amount"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.ticket.amount.$error
                  }"
                /> -->
                <div
                  v-if="submitted && !$v.ticket.amount_ticket.required"
                  class="invalid-feedback"
                >
                  O campo nome é obrigatório.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8">
              <div class="form-group">
                <label for="description">Descrição</label>
                <input
                  id="description"
                  v-model="ticket.description"
                  name="description"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.ticket.description.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.ticket.description.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="type">Tipo</label>
                <select
                  class="custom-select"
                  name="type_ticket"
                  v-model="ticket.type_ticket"
                  :class="{
                    'is-invalid': submitted && $v.ticket.type_ticket.$error
                  }"
                >
                  <option value=""></option>
                  <option value="0">PRESENCIAL</option>
                  <option value="1">ONLINE</option>
                </select>
                <div
                  v-if="submitted && !$v.ticket.type_ticket.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveTicket()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { Money } from 'v-money'

export default {
  components: {
    Loading,
    Money
  },
  props: {
    idTicket: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo Ingresso',
      loading: true,
      fullPage: true,
      submitted: false,
      ticket: {},
      modal: false,
      price: 0.00,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    }
  },
  validations: {
    ticket: {
      name: { required },
      amount_ticket: { required },
      type_ticket: { required },
      description: { required }
    }
  },
  methods: {
    ...mapActions('ticket', {
      getTicket: 'show',
      postData: 'save'
    }),
    loadItem (id) {
      if (id) {
        this.title = 'Editar Ingresso'
        this.getTicket(id).then((data) => {
          this.ticket = this.data
          this.price = this.ticket.amount_ticket
          // this.ticket.amount = this.data.amount.toString(2)
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveTicket () {
      this.ticket.amount_ticket = this.price
      this.submitted = true
      this.$v.ticket.$touch()
      if (this.$v.ticket.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.ticket).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.ticket = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('ticket', { data: 'ticket' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
