<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form @submit.prevent="saveWA()">
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12">
                  <label for="whatsapp">Whatsapp</label>
                  <select
                    class="custom-select"
                    name="whatsapp"
                    id="whatsapp"
                    v-model="wa.whatsapp"
                    :class="{
                      'is-invalid': submitted && $v.wa.whatsapp.$error,
                    }">
                      <option value=""></option>
                      <option value="1">Ativo</option>
                      <option value="0">Desativado</option>
                  </select>
                  <div
                    v-if="submitted && !$v.wa.whatsapp.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório.
                  </div>
                </div>
              </div>
              <span v-if="parseInt(wa.whatsapp) === 1">
                <div class="row mt-3">
                  <div class="col-sm-6">
                    <label for="key_workspace">Chave Workspace</label>
                    <input
                      id="key_workspace"
                      name="key_workspace"
                      v-model="wa.key_workspace"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.wa.key_workspace.$error,
                      }"
                    />
                    <div
                      v-if="submitted && !$v.wa.key_workspace.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório.
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label for="key_number">Chave Número</label>
                    <input
                      id="key_number"
                      name="key_number"
                      v-model="wa.key_number"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.wa.key_number.$error,
                      }"
                    />
                    <div
                      v-if="submitted && !$v.wa.key_number.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório.
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-12">
                    <label for="token_positus">Token Positus</label>
                    <textarea
                      id="token_positus"
                      name="token_positus"
                      v-model="wa.token_positus"
                      class="form-control"
                      rows="5"
                      :class="{
                        'is-invalid': submitted && $v.wa.token_positus.$error,
                      }"
                    />
                    <div
                      v-if="submitted && !$v.wa.token_positus.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório.
                    </div>
                  </div>
                </div>
              </span>
              <div class="row mt-3 mb-2">
                <div class="col-sm-12">
                  <div class="float-right">
                    <button type="submit" class="btn btn-primary mr-1">
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
      <div class="card">
        <div class="card-body">
          <b-tabs nav-class="nav-tabs-custom" content-class="p-3 text-muted" lazy>
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block">Templates</span>
              </template>
              <WATemplates />
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-envelope"></i>
                </span>
                <span class="d-none d-sm-inline-block">Configurações de status</span>
              </template>
              <WAStatusTemplates />
            </b-tab>
          </b-tabs>
        </div>
      </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import WATemplates from './templates/Index.vue'
import WAStatusTemplates from './status/Index.vue'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    Layout,
    PageHeader,
    WATemplates,
    WAStatusTemplates
  },
  name: 'setting-rsvp',
  page: {
    title: 'Parâmetros do WhatsApp',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      submitted: false,
      configEvent: false,
      wa: {},
      title: 'Parâmetros do WhatsApp',
      items: [{
        text: 'Configurações gerais',
        href: '/'
      },
      {
        text: 'Parâmetros do WhatsApp',
        active: true
      }
      ]
    }
  },
  validations () {
    let wa = {
      whatsapp: { required }
    }

    if (parseInt(this.wa.whatsapp) === 1) {
      wa = {
        ...wa,
        key_workspace: { required },
        key_number: { required },
        token_positus: { required }
      }
    }
    console.log(wa)
    return {
      wa: wa
    }
  },
  mounted () {
    let loader = this.$loading.show()
    this.getSettingWA().then(() => {
      console.log(this.data)
      this.wa = this.data
      loader.hide()
    })
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('wa', { data: 'settingWA' })
  },
  methods: {
    ...mapActions('wa', {
      getSettingWA: 'GetSettingWA',
      postSettingWA: 'saveWA'
    }),
    initSetting () {
      this.configEvent = true
    },
    async saveWA () {
      this.submitted = true
      this.$v.wa.$touch()
      if (this.$v.wa.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      try {
        await this.postSettingWA(this.wa).then(() => {
          let message = 'Sucesso: Dados atualizados com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
        })
      } catch (err) {
        console.log(err)
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    }
  }
}
</script>
<style>
</style>
