<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ title }}</h4>
            <div class="row mb-2 ">
              <div class="col-sm-8">
              </div>
              <div class="col-sm-4">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right me-2">
                    <button type="button" class="btn  btn-primary btn-rounded mb-2 mr-2" @click="showFilters()">
                      <i class="mdi mdi-filter mr-1"></i> Filtros
                    </button>
                  </div>
                  <div class="text-sm-right">
                    <button type="button" class="btn  btn-success btn-rounded mb-2 mr-2" @click="exportParticipants()">
                      <i class="mdi mdi-file-export-outline mr-1"></i> Exportar Dados
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th width="40%">Nome</th>
                    <th width="20%">Grupo</th>
                    <th width="10%">Inscrição</th>
                    <th width="10%">Atualização</th>
                    <th width="20%">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in participants" :key="list.id" :class="{'inativado': parseInt(list.status) === 5}">
                    <td>
                      <h5 class="font-size-14 mb-1" style="white-space: nowrap;text-overflow: ellipsis;max-width: 275px;overflow: hidden;">{{ list.name }}</h5>
                      <p class="text-muted mb-0">{{ list.email }}</p>
                    </td>
                    <td>
                      <span class="badge badge-soft-secondary font-size-12" style="font-weight: 400; white-space: nowrap;text-overflow: ellipsis;max-width: 260px;overflow: hidden;">
                        {{ group[list.type] }} {{ list.event_companion ? ' EVENTO' : '' }} {{ list.event_companion && list.travel_companion ? ' E ' : '' }} {{ list.travel_companion ? 'VIAGEM' : '' }}
                      </span>
                    </td>
                    <td style="font-size: 12px !important">{{ list.inscricao }}</td>
                    <td style="font-size: 12px !important">{{ list.atualizacao }}</td>
                    <td>
                      <span
                        :class="statusClass[list.status]"
                        class="badge font-size-12 p-2" style="min-width: 120px">
                          {{ statusList[list.status] }}
                      </span><br />
                      <span
                        v-if="list.ref_status"
                        class="badge font-size-11 p-1 badge-secondary" style="min-width: 120px;margin-top:2px">
                          {{ refStatus[list.ref_status] }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-filter ref="modalFilter" @participantFiltered="getReport"></modal-filter>
  </div>
</template>
<script>
// Import component
import appConfig from '@/app.config'
import serviceReport from '../services'
import modalFilter from '@/components/modals/filters/ParticipantFilter'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    modalFilter
  },
  name: 'report-participantsAll',
  page: {
    title: 'Relatório de Participantes e Acompanhantes',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      avatar: require('@/assets/images/users/user-default.png'),
      params: {},
      totalRows: 1,
      rows: 0,
      currentPage: 1,
      participants: [],
      statusClass: ['badge-dark', 'badge-primary', 'badge-warning', 'badge-success', 'badge-danger', 'badge-secondary'],
      refStatus: ['', 'Documentação', 'Contato telefônico', 'Emissão aéreo', 'Emissão transfer', 'Confirmação', 'Emissão Dados Aéreo'],
      group: [
        'ACOMPANHANTE',
        'SUPERINTENDENTES MATRIZ E FUNCIONÁRIOS MATRIZ',
        'SUPERINTENDENTES E GERENTES COMERCIAIS, GERENTE DE ESCRITÓRIO, PARTNER, CONSULTORES E PROMOTORES',
        'DIRETORIA, CONSELHO, FAMILIA E PARCEIROS',
        'AEGON',
        'GALISTAS',
        'DESTAQUES',
        'DESTAQUES COM ACOMPANHANTE',
        'IMPRENSA',
        'CORRETORES CONVIDADOS COM CARTEIRA',
        'CORRETORES CONVIDADOS SEM CARTEIRA',
        'PARTICIPANTES GERAIS - PARTICIPAM APENAS NA MODALIDADE ONLINE, PAGANDO',
        'FUNCIONÁRIOS UNIDADES – PARTICIPAM ONLINE'
      ],
      perPage: 20,
      title: 'Relatório de Participantes',
      items: [{
        text: 'Relatórios'
      },
      {
        text: 'Participantes',
        active: true
      }
      ]
    }
  },
  mounted () {
    let loeader = this.$loading.show()
    serviceReport.listParticipants({ limit: this.perPage }).then((res) => {
      this.participants = res.data
      this.rows = res.meta.pagination.total
      this.currentPage = res.meta.pagination.current_page
      loeader.hide()
    }).catch(error => {
      console.log(error)
      loeader.hide()
    })
    this.totalRows = this.rows
    this.GetStatus()
  },
  created () {},
  computed: {
    ...mapState('event', ['statusList'])
  },
  methods: {
    ...mapActions('event', { GetStatus: 'GetStatusList' }),
    showFilters () {
      this.$refs.modalFilter.open()
    },
    exportParticipants () {
      let loeader = this.$loading.show()
      serviceReport.exportParticipantsAll(this.params).then((res) => {
        this.download(res)
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    getReport (filter = '') {
      let loeader = this.$loading.show()
      let params = {
        type: filter.type || null,
        limit: this.perPage,
        page: this.currentPage,
        status: filter.status || null,
        city: filter.city || null,
        district: filter.district || null,
        isOnline: filter.isOnline || null,
        need_payment: filter.need_payment || null,
        payment_completed: filter.payment_completed || null,
        created_start: filter.created_start ? this.$moment(filter.created_start).format('YYYY-MM-DD') : null,
        created_end: filter.created_end ? this.$moment(filter.created_end).format('YYYY-MM-DD') : null,
        confirmation_date_start: filter.confirmation_date_start ? this.$moment(filter.confirmation_date_start).format('YYYY-MM-DD') : null,
        confirmation_date_end: filter.confirmation_date_end ? this.$moment(filter.confirmation_date_end).format('YYYY-MM-DD') : null,
        aereo: filter.aereo || null,
        companion: filter.companion || null,
        food_restriction: filter.food_restriction || null
      }
      params.tags = filter.tagsIds || null

      this.params = params
      serviceReport.listParticipants(params).then((res) => {
        this.participants = res.data
        if (res.data.length > 0) {
          this.rows = res.meta.pagination.total
          this.currentPage = res.meta.pagination.current_page
        }
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    updateList () {
      this.getReport(this.params)
    }
  },
  watch: {
    currentPage () { this.updateList() },
    total () { this.updateList() },
    dataPerPage () { this.updateList() }
  }
}
</script>
<style scoped>
.inativado { background: #f7ebeb }
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
</style>
