<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
        <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-6">
                <b-dropdown id="dropdown-dropright" :text="`${perPage} itens por página`" variant="outline-dark" dropright size="sm" class="m-2">
                <b-dropdown-item @click="limitPage(20)">20 itens por página</b-dropdown-item>
                <b-dropdown-item @click="limitPage(50)">50 itens por página</b-dropdown-item>
                <b-dropdown-item @click="limitPage(100)">100 itens por página</b-dropdown-item>
                <b-dropdown-item @click="limitPage(1000)">1000 itens por página</b-dropdown-item>
              </b-dropdown>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right">
                    <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newSchedule()">
                      <i class="mdi mdi-plus mr-1"></i> Nova Programação
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th style="width: 20px;"></th>
                    <th style="width: 140px;">Data</th>
                    <th style="width: 85%">Título</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                  <draggable
                    v-model="schedules"
                    :element="'tbody'"
                    :options="{animation:200, handle:'.mover'}"
                    @change="updateSequence"
                  >
                      <tr v-for="grid in schedules" :key="grid.id">
                        <td class="mover">
                            <span class="badge font-size-13 badge-soft-primary p-1 badge-num">{{ grid.sequence }}</span>
                        </td>
                        <td class="mover">
                          <h5 class="font-size-14 mb-1">
                            {{ grid.schedule_day }}
                            <span class="text-muted mb-0">{{ grid.schedule_month }}</span>
                          </h5>
                          <span class="text-muted">{{ grid.start_time | formatTime }} / {{ grid.end_time | formatTime }}</span>
                        </td>
                        <td class="mover">
                          <h5 class="font-size-14 mb-1">
                            <span class="text-dark">{{ grid.title }}</span>
                          </h5>
                          <p class="text-muted mb-0">{{ grid.schedule_type }}</p>
                        </td>
                        <td>
                          <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                            <template v-slot:button-content>
                              <i class="mdi mdi-dots-horizontal font-size-18"></i>
                            </template>
                            <b-dropdown-item @click="editSchedule(grid.id)">
                              <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                            </b-dropdown-item>
                            <b-dropdown-item @click="deleteSchedule(grid.id)">
                              <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                            </b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                  </draggable>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import draggable from 'vuedraggable'
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  components: {
    draggable,
    Layout,
    PageHeader
  },
  name: 'Programação',
  page: {
    title: 'Programação',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      params: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      statusLabel: ['Inativo', 'Ativo'],
      imgSrc: '',
      cropImg: '',
      submitted: false,
      title: 'Programação',
      items: [{
        text: 'Programação'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  filters: {
    display: function (value) {
      if (value === 1) {
        return 'Web'
      }
      if (value === 0) {
        return 'App'
      }
      if (value === 10) {
        return 'Web e App'
      }
      return ''
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.currentPage
    this.loading = false
  },
  computed: {
    ...mapGetters('schedules', { data: 'SCHEDULES' }),
    ...mapState('schedules', ['pagination']),
    rows () {
      return this.pagination.total
    },
    schedules: {
      get () {
        return this.data
      },
      set (value) {
        let loader = this.$loading.show()
        this.updateSequence({ itens: value }).then(res => {
          loader.hide()
          successMessage('Sucesso: Ordem atualizada.', 2500)
        })
      }
    }
  },
  methods: {
    ...mapActions('schedules', {
      GetSchedules: 'GetSchedules',
      changeStatus: 'changeStatus',
      deleteItem: 'deleteSchedule',
      updateSequence: 'orderList'
    }),
    newSchedule () {
      this.$router.push({
        name: 'site-schedule-new'
      })
    },
    editSchedule (id) {
      this.$router.push({
        name: 'site-schedule-edit',
        params: {
          id: id
        }
      })
    },
    async toggleStatus (id) {
      try {
        await this.changeStatus(id)
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    deleteSchedule (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem(id)
            successMessage('Sucesso: Bloco excluído com sucesso.', 2500)
            this.updateList()
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    updateList () {
      let loader = this.$loading.show()
      this.GetSchedules({ limit: this.perPage, include: 'room,participants', page: this.currentPage }).then(() => {
        loader.hide()
      })
    },
    limitPage (limit) {
      this.perPage = limit
      this.updateList()
    }
  },
  watch: {
  }
}
</script>
<style scoped>
.bleft { border-left: 3px solid #343f56 !important; padding:0.75rem }
.inline { display: inline !important; }
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.float-sm-end { float: right }
.table-responsive { min-height: 300px;}
</style>
