<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="form-group row mb-4">
        <label for="name" class="col-form-label col-lg-2">Filtrar por TAG:</label>
        <div class="col-lg-9">
          <select
            class="custom-select"
            name="tag"
            v-model="filter.tag"
          >
            <option v-for="(s,i) in tags" :key="i" :value="s.id">{{ s.name }}</option>
          </select>
        </div>
        <div class="col-lg-1">
          <button class="btn btn-sm btn-primary float-left mt-1" @click="applyFilter()">Aplicar</button>
        </div>
      </div>
    </div>
    <div class="col-xl-12" v-show="statData.length">
      <div class="row">
        <div v-for="stat of statData" :key="stat.icon" class="col-md-3">
          <Stat :data="stat || []" />
        </div>
        <div class="col-md-3">
          <div class="card mini-stats-wid">
            <div class="card-body" style="padding: 1rem">
              <h6 class="mt-1 mb-1">Resetar check-in</h6>
              <div class="mail-list mt-1">
                <a href="javascript: void(0);">
                  Check-Out em todos
                  <button class="btn btn-sm btn-danger float-right" @click="cleanCheckins()">Limpar</button>
                </a>
              </div>
            </div>
            <!-- end card-body -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Stat from '@/components/widgets/statCheckin'
import Swal from 'sweetalert2'
import 'vue-loading-overlay/dist/vue-loading.css'
import http from '@/http'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState, mapGetters } from 'vuex'
import service from '@/pages/settingscheckin/services'

export default {
  components: { Stat },
  data () {
    return {
      settings: {
        has_checkout: false
      },
      filter: {},
      timeout: 60000,
      statData: []
    }
  },
  beforeMount () {
    this.getSettingCheckin()
  },
  methods: {
    ...mapActions('tag', {
      getAll: 'getAll'
    }),
    ...mapActions('settingcheck', {
      settingsCheckin: 'getAll',
      resetCheckin: 'resetCheckin'
    }),
    applyFilter () {
      console.log(this.filter)
      this.getSubscribers()
      // if (typeof (this.selected) === 'object') {
      //   this.filter.district = this.selected.value
      // }
    },
    getSubscribers () {
      http.get(`/admin/metrics/checkin`, { params: this.filter }).then((response) => {
        this.statData = response.data
      }).catch((error) => {
        console.error(error)
      })
    },
    getTags () {
      let params = {
        search: this.search || null,
        limit: 100,
        page: 1
      }
      this.params = params
      this.getAll(this.params).then((res) => {
      }).catch(error => {
        console.log(error)
      })
    },
    getSettingCheckin () {
      let loader = this.$loading.show()
      this.settingsCheckin()
        .then(() => {
          this.settings = this.settingcheckin
        }).then(() => { loader.hide() })
        .catch((error) => {
          console.error(error)
          loader.hide()
          errorMessage('Atenção:' + error.message)
        })
    },
    cancelAutoUpdate () {
      clearInterval(this.timeout)
    },
    getSettings () {
      let loader = this.$loading.show()
      this.settings = this.settingcheckin
      loader.hide()
    },
    toggleControl (key, status) {
      if (status !== '') {
        let payload = {
          [key]: status ? 1 : 0
        }
        service.toogleSetting(payload)
          .then((data) => {
            successMessage('Sucesso: ' + data.data.message, 2500)
          }).catch((error) => {
            this.settings[key] = false
            errorMessage('Atenção: ' + error.message)
          })
      }
    },
    cleanCheckins () {
      Swal.fire({
        title: 'Você tem certeza?',
        html: `Todos os participantes que estão com status <b>check-in</b> serão marcados como <b>check-out</b>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#388741',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, MUDAR STATUS'
      }).then(result => {
        if (result.value) {
          try {
            this.resetCheckin().then((res) => {
              successMessage('Sucesso: Status atualizado.', 2500)
            }).then(() => {
              this.getSubscribers()
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            console.log(err)
          }
        }
      })
    }
  },
  computed: {
    // ...mapState('settingcheck', ['settingcheckin']),
    ...mapGetters('settingcheck', {
      settingcheckin: 'SETTINGCHECKIN'
    }),
    ...mapState('tag', ['tags'])
  },
  created () {
    // this.$parent.$on('update', this.setValue)
  },
  beforeDestroy () {
    this.cancelAutoUpdate()
  },
  mounted () {
    this.getSubscribers()
    this.timeout = setInterval(() => {
      this.getSubscribers()
    }, 30000)
    this.getTags()
    // this.connectSocket()
  }
}
</script>
<style>
.col-md-2-5 { width: 18.99% !important; margin: 5px }
.col-md-2-5:first { margin-left: 15px }
</style>
