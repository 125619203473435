var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveAccreditation()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('label',{attrs:{"for":"has_auto_credential"}},[_vm._v("Auto credenciamento?")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.accreditation.has_auto_credential),expression:"accreditation.has_auto_credential"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.accreditation.has_auto_credential.$error,
                  },attrs:{"name":"has_auto_credential","id":"has_auto_credential"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.accreditation, "has_auto_credential", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}}),_c('option',{attrs:{"value":"1"}},[_vm._v("SIM")]),_c('option',{attrs:{"value":"0"}},[_vm._v("NÃO")])]),(_vm.submitted && !_vm.$v.accreditation.has_auto_credential.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()])]),(parseInt(_vm.accreditation.has_auto_credential) === 1)?_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-sm-12"},[_c('label',{attrs:{"for":"auto_checkin_after_credential"}},[_vm._v("Check-in automático após auto credenciamento?")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.accreditation.auto_checkin_after_credential),expression:"accreditation.auto_checkin_after_credential"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.accreditation.auto_checkin_after_credential.$error,
                  },attrs:{"name":"auto_checkin_after_credential","id":"auto_checkin_after_credential"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.accreditation, "auto_checkin_after_credential", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}}),_c('option',{attrs:{"value":"0"}},[_vm._v("NÃO")]),_c('option',{attrs:{"value":"1"}},[_vm._v("SIM")])]),(_vm.submitted && !_vm.$v.accreditation.auto_checkin_after_credential.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()]),_c('div',{staticClass:"col-sm-12 mt-3"},[_c('label',{attrs:{"for":"auth_accreditation"}},[_vm._v("Modo de autenticação")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.accreditation.auth_accreditation),expression:"accreditation.auth_accreditation"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.accreditation.auth_accreditation.$error,
                  },attrs:{"name":"auth_accreditation","id":"auth_accreditation"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.accreditation, "auth_accreditation", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}}),_c('option',{attrs:{"value":"1"}},[_vm._v("E-mail")]),_c('option',{attrs:{"value":"2"}},[_vm._v("CPF")])]),(_vm.submitted && !_vm.$v.accreditation.auth_accreditation.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()])]):_vm._e()])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title mb-4"},[_vm._v("Etiquetas")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('label',{attrs:{"for":"model_label"}},[_vm._v("Modelo de etiqueta")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.accreditation.model_label),expression:"accreditation.model_label"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.accreditation.model_label.$error,
                  },attrs:{"name":"model_label","id":"model_label"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.accreditation, "model_label", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}}),_c('option',{attrs:{"value":"1"}},[_vm._v("Modelo 1 (Nome)")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Modelo 2 (Nome + QRCode)")])]),(_vm.submitted && !_vm.$v.accreditation.model_label.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"label",class:{'box-qrcode': parseInt(_vm.accreditation.model_label) === 2}},[(parseInt(_vm.accreditation.model_label) === 2)?_c('span',{staticClass:"qrcode"},[_c('qrcode-vue',{attrs:{"value":_vm.qrcodeSetting.value,"size":_vm.qrcodeSetting.size,"level":"L"}}),_c('span',{staticClass:"number-qrcode"},[_vm._v("H982CMX1")])],1):_vm._e(),(parseInt(_vm.accreditation.model_label) === 2)?_c('span',{class:{'info-label-default': parseInt(_vm.accreditation.model_label) === 1,
                    'info-label-model2': parseInt(_vm.accreditation.model_label) === 2}},[_c('span',{class:{'name-participant-qrcode': parseInt(_vm.accreditation.model_label) === 2,
                      'name-participant-noqrcode': parseInt(_vm.accreditation.model_label) === 1}},[_vm._v("PARTICIPANTE TESTE")])]):_vm._e(),(parseInt(_vm.accreditation.model_label) === 1)?_c('span',{staticClass:"info-label-noqrcode"},[_c('span',{class:{'name-participant-qrcode': parseInt(_vm.accreditation.model_label) === 2,
                      'name-participant-noqrcode': parseInt(_vm.accreditation.model_label) === 1}},[_vm._v("PARTICIPANTE TESTE")])]):_vm._e()])])])])])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn btn-dark mr-4",attrs:{"type":"buton"},on:{"click":function($event){return _vm.cancelar()}}},[_vm._v(" Cancelar ")]),_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v(" Salvar ")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }