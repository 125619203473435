<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="savePartner()">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Informações do Patrocinador</h4>
              <div class="row">
                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="name">Logo</label>
                    <input
                      ref="logo"
                      type="file"
                      name="logo"
                      class="form-control"
                      accept="image/*"
                      @change="setImage"
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="name">Nome</label>
                    <input
                      id="name"
                      v-model="sponsor.name"
                      name="name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.sponsor.name.$error,
                      }"
                    />
                    <div
                      v-if="submitted && !$v.sponsor.name.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="sponsor_category_id">Categoria</label>
                    <multiselect
                      v-model="selected"
                      :options="categories" class="helo"
                      @input="setCategory"
                      :max-height="180"
                      trackBy="id"
                      label="name"
                      placeholder="Selecione uma opção"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="website">Website</label>
                    <input
                      id="website"
                      v-model="sponsor.website"
                      name="website"
                      type="text"
                      class="form-control"
                      placeholder="URL do Website"
                      :class="{
                        'is-invalid': submitted && $v.sponsor.website.$error,
                      }"
                    />
                    <div
                      v-if="submitted && !$v.sponsor.website.url"
                      class="invalid-feedback"
                    >
                    informe uma url válida.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="float-right">
                <button type="buton" @click="cancelar()" class="btn btn-dark mr-4">
                  Cancelar
                </button>
                <button type="submit" class="btn btn-primary mr-1">
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>
<script>
import Multiselect from 'vue-multiselect'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required, url } from 'vuelidate/lib/validators'
import service from './services'

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect
  },
  name: 'Patrocinador',
  page: {
    title: 'Patrocinador',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      categories: [],
      selected: '',
      cropImg: '',
      sponsor: {
        id: '',
        name: '',
        website: '',
        logo: ''
      },
      submitted: false,
      title: 'Patrocinador',
      items: [{
        text: 'Patrocinadores',
        href: '/settings/site/sponsors'
      },
      {
        text: 'Novo Patrocinador',
        active: true
      }
      ]
    }
  },
  validations: {
    sponsor: {
      name: { required },
      website: { url }
    }
  },
  mounted () {
    this.getCategories()
    this.loadItem()
  },
  computed: {
    ...mapState('sponsors', { data: 'sponsor' })
  },
  methods: {
    ...mapActions('sponsors', {
      fetch: 'show',
      postData: 'save'
    }),
    setImage (e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        errorMessage('Alerta: Por favor selecione uma imagem.')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        errorMessage('Alerta: Tamanho máximo 2MB!')
        return
      }
      if (typeof FileReader === 'function') {
        this.cropImg = file
      } else {
        errorMessage('ERROR: Browser não suporta FileReader')
      }
    },
    cancelar () {
      this.$router.push({ name: 'site-sponsors' })
    },
    getCategories () {
      service.getCategories().then(({ data }) => {
        this.categories = data
      })
    },
    setCategory (category) {
      if (category) {
        this.sponsor.sponsor_category_id = category.id
        return
      }
      this.sponsor.sponsor_category_id = ''
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.items[1].text = 'Editar Patrocinador'
        this.fetch(this.$route.params.id)
          .then(() => {
            this.sponsor = this.data
            this.selected = this.categories.filter(o => o.id === parseInt(this.sponsor.sponsor_category_id))[0]
          }).then(() => {
            loader.hide()
          })
      }
      let loader = this.$loading.show()
      loader.hide()
    },
    async savePartner () {
      this.submitted = true
      this.$v.sponsor.$touch()
      if (this.$v.sponsor.$invalid) {
        console.log('teste', this.$v.sponsor)
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      const formData = new FormData()
      formData.append('id', this.sponsor.id || '')
      if (this.sponsor.sponsor_category_id) {
        formData.append('sponsor_category_id', this.sponsor.sponsor_category_id)
      }
      formData.append('name', this.sponsor.name)
      formData.append('website', this.sponsor.website || '')

      if (this.cropImg) {
        let ext = this.cropImg.type.split('/')[1]
        formData.append('logo', this.cropImg, 'logo.' + ext)
      }

      await this.postData(formData).then(res => {
        let message = 'Sucesso: Dados atualizados com sucesso.'
        let delay = 2500
        loader.hide()
        successMessage(message, delay)
        this.cancelar()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        loader.hide()
      })
    }
  }
}
</script>
<style>
</style>
