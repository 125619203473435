<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="status">Status</label>
                <select
                  class="custom-select"
                  name="status"
                  v-model="status.status"
                  :class="{
                    'is-invalid': submitted && $v.status.status.$error
                  }"
                >
                <option v-for="(p, i) in statusList" :key=i :value="i">{{ p }}</option>
                </select>
                <div
                  v-if="submitted && !$v.status.status.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="name">Template</label>
                <select
                  class="custom-select"
                  name="template_name"
                  v-model="status.template_id"
                  :class="{
                    'is-invalid': submitted && $v.status.template_id.$error
                  }"
                >
                  <option value=""></option>
                  <option v-for="(t,i) in templates" :key=i :value="t.id">{{ t.namespace }}</option>
                </select>
                <div
                  v-if="submitted && !$v.status.template_id.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveStatus()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
import Swal from 'sweetalert2'
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading
  },
  props: {
    idTemplate: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo',
      loading: true,
      fullPage: true,
      submitted: false,
      template: {},
      status: {},
      modal: false
    }
  },
  validations: {
    status: {
      status: { required },
      template_id: { required }
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions('template', {
      getTemplatesAll: 'getAll'
    }),
    ...mapActions('settings', { GetStatus: 'GetStatusList' }),
    ...mapActions('statusTemplate', {
      getStatusTemplate: 'show',
      postData: 'save'
    }),
    deleteTemplate (template) {
      Swal.fire({
        title: 'Você tem certeza ?',
        html: `Será excluido o template: <b>${template.name}</b>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.delete(template.id)
            successMessage('Sucesso: Template excluído com sucesso.', 2500)
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    updateList () {
      this.getReport(this.params)
    },
    setTemplate (template) {
      if (template) {
        this.template.name = template.name
        this.template.namespace = template.name
        this.template.language_code = template.language.code
        this.template.components = template.components
        return
      }
      this.template = {}
    },
    loadItem (id) {
      if (id) {
        this.title = 'Editar Status'
        this.getStatusTemplate(id).then((data) => {
          this.status = this.data
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      this.getTemplatesAll()
      this.GetStatus()
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveStatus () {
      this.submitted = true
      this.$v.status.$touch()
      if (this.$v.status.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.status).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.template = {}
        this.$v.$reset()
        this.loading = false
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.status = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('settings', ['statusList']),
    ...mapState('template', ['templates']),
    ...mapState('statusTemplate', { data: 'statusTemplate' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
