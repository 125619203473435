<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form @submit.prevent="saveRSVP()">
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6">
                  <label for="type_rsvp">Modelo de RSVP</label>
                  <select
                    class="custom-select"
                    name="type_rsvp"
                    id="type_rsvp"
                    v-model="rsvp.type_rsvp"
                    :class="{
                      'is-invalid': submitted && $v.rsvp.type_rsvp.$error,
                    }">
                      <option value=""></option>
                      <option value="1">Ativo</option>
                      <option value="2">Passivo</option>
                  </select>
                  <div
                    v-if="submitted && !$v.rsvp.type_rsvp.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório.
                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="registration_status">Status de inscrição</label>
                  <select
                    class="custom-select"
                    name="registration_status"
                    id="registration_status"
                    v-model="rsvp.registration_status"
                    :class="{
                      'is-invalid': submitted && $v.rsvp.registration_status.$error,
                    }">
                      <option value=""></option>
                      <option value="0">Fechado</option>
                      <option value="1">Aberto</option>
                      <option value="2">Programado para encerrar</option>
                  </select>
                  <div
                    v-if="submitted && !$v.rsvp.registration_status.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório.
                  </div>
                </div>
              </div>
              <div class="row mt-3" v-if="parseInt(rsvp.registration_status) === 0 || parseInt(rsvp.registration_status) === 2">
                <div
                  class="col-sm-6"
                  v-if="parseInt(rsvp.registration_status) === 2"
                >
                  <label for="registration_closing_date">Data/hora de encerramento</label>
                  <date-picker
                    v-model="rsvp.registration_closing_date"
                    type="datetime"
                    :first-day-of-week="1"
                    lang="pt-br"
                    format="DD/MM/YYYY HH:mm"
                    name="registration_closing_date"
                    :class="{
                      'is-invalid': submitted && $v.rsvp.registration_closing_date.$error,
                  }"
                  >
                  </date-picker>
                  <div
                    v-if="submitted && !$v.rsvp.registration_closing_date.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório.
                  </div>
                </div>
                <div
                  class="col-sm-6"
                  :class="{'col-sm-12': parseInt(rsvp.registration_status) === 0, 'col-sm-6': parseInt(rsvp.registration_status) === 2}"
                >
                  <label for="registration_closing_message">Mensagem exibida quando as incrições são encerradas</label>
                  <input
                    id="registration_closing_message"
                    name="registration_closing_message"
                    v-model="rsvp.registration_closing_message"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.rsvp.registration_closing_message.$error,
                    }"
                    placeholder="Informe o nome do evento"
                  />
                  <div
                    v-if="submitted && !$v.rsvp.registration_closing_message.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório.
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-12">
                  <label for="limit_registration">Limitar número de inscrições?</label>
                  <select
                    class="custom-select"
                    name="limit_registration"
                    id="limit_registration"
                    v-model="rsvp.limit_registration"
                    :class="{
                      'is-invalid': submitted && $v.rsvp.limit_registration.$error,
                    }">
                      <option value=""></option>
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                  </select>
                  <div
                    v-if="submitted && !$v.rsvp.limit_registration.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório.
                  </div>
                </div>
              </div>
              <span v-if="parseInt(rsvp.limit_registration) === 1">
                <div class="row mt-3">
                  <div class="col-sm-6">
                    <label for="limit_registration_max">Número máximo</label>
                    <input
                      id="limit_registration_max"
                      name="limit_registration_max"
                      v-model="rsvp.limit_registration_max"
                      type="number"
                      class="form-control"
                    />
                    <div
                      v-if="submitted && !$v.rsvp.limit_registration_max.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório.
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label for="limit_registration_mode">Modo de limite</label>
                    <select
                      class="custom-select"
                      name="limit_registration_mode"
                      id="limit_registration_mode"
                      v-model="rsvp.limit_registration_mode"
                      :class="{
                        'is-invalid': submitted && $v.rsvp.limit_registration_mode.$error,
                      }">
                        <option value=""></option>
                        <option value="1">Inscritos</option>
                        <option value="2">Confirmados</option>
                    </select>
                    <div
                      v-if="submitted && !$v.rsvp.limit_registration_mode.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório.
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-6">
                    <label for="limit_registration_message">Mensagem exibida quando os registros estão cheios</label>
                    <input
                      id="limit_registration_message"
                      name="limit_registration_message"
                      v-model="rsvp.limit_registration_message"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.rsvp.limit_registration_message.$error,
                      }"
                      placeholder="Informe o nome do evento"
                    />
                    <div
                      v-if="submitted && !$v.rsvp.limit_registration_message.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório.
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label for="waiting_list">Lista de espera</label>
                    <select
                      class="custom-select"
                      name="waiting_list"
                      id="waiting_list"
                      v-model="rsvp.waiting_list"
                      :class="{
                        'is-invalid': submitted && $v.rsvp.waiting_list.$error,
                      }">
                        <option value=""></option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                    </select>
                    <div
                      v-if="submitted && !$v.rsvp.waiting_list.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório.
                    </div>
                  </div>
                </div>
              </span>
              <div class="row mt-3">
                <div class="col-sm-6">
                  <label for="registration_type">Tipo de inscrição</label>
                  <select
                    class="custom-select"
                    name="registration_type"
                    id="registration_type"
                    v-model="rsvp.registration_type"
                    :class="{
                      'is-invalid': submitted && $v.rsvp.registration_type.$error,
                    }">
                      <option value=""></option>
                      <option value="1">Simples</option>
                      <option value="2">Com acompanhante</option>
                  </select>
                  <div
                    v-if="submitted && !$v.rsvp.registration_type.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório.
                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="registration_model">Modelo de inscrição</label>
                  <select
                    class="custom-select"
                    name="registration_model"
                    id="registration_model"
                    v-model="rsvp.registration_model"
                    :class="{
                      'is-invalid': submitted && $v.rsvp.registration_model.$error,
                    }">
                      <option value=""></option>
                      <option value="1">Formulário ativo</option>
                      <option value="2">Formulário passivo</option>
                  </select>
                  <div
                    v-if="submitted && !$v.rsvp.registration_model.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="float-right">
            <button type="buton" @click="cancelar()" class="btn btn-dark mr-4">
              Cancelar
            </button>
            <button type="submit" class="btn btn-primary mr-1">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </form>
  </Layout>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pt-br'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    Layout,
    DatePicker,
    PageHeader
  },
  name: 'setting-rsvp',
  page: {
    title: 'Parâmetros do RSVP',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      submitted: false,
      configEvent: false,
      rsvp: {},
      title: 'Parâmetros do RSVP',
      items: [{
        text: 'Configurações gerais',
        href: '/'
      },
      {
        text: 'Parâmetros do RSVP',
        active: true
      }
      ]
    }
  },
  validations () {
    let rsvp = {
      type_rsvp: { required },
      registration_status: { required },
      limit_registration: { required },
      registration_type: { required },
      registration_model: { required }
    }

    if (parseInt(this.rsvp.registration_status) === 2) {
      rsvp = { ...rsvp, registration_closing_date: { required } }
    }

    if (parseInt(this.rsvp.registration_status) !== 1) {
      rsvp = { ...rsvp, registration_closing_message: { required } }
    }
    console.log(this.rsvp.limit_registration)
    if (this.rsvp.limit_registration > 0) {
      rsvp = {
        ...rsvp,
        limit_registration_max: { required },
        limit_registration_mode: { required },
        limit_registration_message: { required },
        waiting_list: { required }
      }
    }
    return {
      rsvp: rsvp
    }
  },
  mounted () {
    let loader = this.$loading.show()
    this.GetRSVP().then(() => {
      this.rsvp = this.data
      if (this.rsvp.registration_closing_date) {
        this.rsvp.registration_closing_date = this.$moment(this.rsvp.registration_closing_date, 'YYYY-MM-DD HH:mm').toDate()
      }
      loader.hide()
    })
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('settingRSVP', { data: 'settingRSVP' })
  },
  methods: {
    ...mapActions('settingRSVP', {
      GetRSVP: 'GetRSVP',
      postRSVP: 'SaveRSVP'
    }),
    initSetting () {
      this.configEvent = true
    },
    async saveRSVP () {
      this.submitted = true
      this.$v.rsvp.$touch()
      if (this.$v.rsvp.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      try {
        this.rsvp.registration_closing_date = this.$moment(this.rsvp.registration_closing_date).format('YYYY-MM-DD HH:mm:ss')
        await this.postRSVP(this.rsvp).then(() => {
          let message = 'Sucesso: Dados atualizados com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
        })
      } catch (err) {
        console.log(err)
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    }
  }
}
</script>
<style>
</style>
