<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="loadReport()">
              <div class="row">
                <div class="col-lg-3">
                  <div class="form-group">
                    <label for="type">Tipo</label>
                    <select
                      class="custom-select"
                      name="type"
                      id="type"
                      v-model="report.type">
                      <option value="guests">Convidados</option>
                      <option value="participants">Participantes</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2 mt-4">
                  <button type="submit" class="btn btn-sm btn-primary mr-1">
                    Carregar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {
  },
  name: 'reports',
  page: {
    title: 'Relatórios',
    meta: [{
    }]
  },
  data () {
    return {
      loading: true,
      params: {},
      report: {},
      title: 'Relatórios',
      items: [{
        text: 'Relatórios',
        active: true
      }]
    }
  },
  mounted () {
    this.loading = false
  },
  methods: {
    loadReport () {
      const name = `report-${this.report.type}`
      if (this.$route.name !== name) this.$router.push({ name: name })
    }
  }
}
</script>
<style>
.link { cursor: pointer; }
.avatar-thumb { height: 4rem; width: 6rem;}
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
</style>
