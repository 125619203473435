<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-6">
                <div class="search-box mr-2 mb-2 d-inline-block ">
                  <div class="position-relative">
                    <input type="text" class="form-control" v-model="search" placeholder="Digite e pressione 'ENTER'" @keyup.enter="doSearch"/>
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right" v-if="user.role === 'Admin'">
                    <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newGroup()">
                      <i class="mdi mdi-plus mr-1"></i> Novo Grupo
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th width="30%">Grupo</th>
                    <th width="50%">Descrição</th>
                    <th width="10%">Status</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in groups" :key="list.id">
                    <td class="link" @click="editGroup(list.id)">
                      <span class="badge font-size-14 badge-soft-primary" style="min-width:100px">{{ list.name }}</span>
                    </td>
                    <td>{{ list.description }}</td>
                    <td>
                      <b-dropdown
                        size="sm"
                        style="min-width:100px"
                        :variant="(parseInt(list.status)) === 1 ? 'success' : 'danger'"
                      >
                        <template v-slot:button-content >
                          {{ parseInt(list.status) === 1 ? 'Ativo' : 'Inativo' }}
                          <i class="mdi mdi-chevron-down"></i>
                        </template>
                        <span>
                          <b-dropdown-item-button @click="toggleStatus(list.id)">Ativar</b-dropdown-item-button>
                          <b-dropdown-item-button @click="toggleStatus(list.id)">Inativar</b-dropdown-item-button>
                        </span>
                      </b-dropdown>
                    </td>
                    <td><button v-if="user.role === 'Admin'" type="button" @click="deleteGroup(list)" class="link btn btn-outline-danger btn-sm"><i class="bx bx-trash"></i></button></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" :idGroup="idGroup" ref="formGroup" @created="updateList"></modal-form>
  </Layout>
</template>
<script>
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import ModalForm from './Form'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader,
    ModalForm
  },
  name: 'groups',
  page: {
    title: 'groups',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      search: '',
      timeout: null,
      modalForm: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      idGroup: null,
      perPage: 20,
      statusLabel: ['Inativo', 'Ativo'],
      submitted: false,
      title: 'Grupos de Participantes',
      items: [{
        text: 'Grupos de Participantes'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('group', ['groups', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('group', {
      getAll: 'getAll',
      delete: 'deleteGroup',
      changeStatus: 'changeStatus'
    }),
    newGroup () {
      this.$refs.formGroup.open('')
      this.modalForm = true
    },
    editGroup (id) {
      if (this.user.role === 'Admin') {
        this.$refs.formGroup.open(id)
        this.modalForm = true
        this.idGroup = id
      }
    },
    async toggleStatus (id) {
      try {
        await this.changeStatus(id)
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    deleteGroup (group) {
      Swal.fire({
        title: 'Você tem certeza ?',
        html: `Será excluido o grupo: <b>${group.name}</b>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.delete(group.id)
            successMessage('Sucesso: Grupo excluído com sucesso.', 2500)
            this.updateList()
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
</style>
