<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-4">
                <div class="">
                  </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th width="10%">Tipo E-mail</th>
                    <th width="15%">Remetente</th>
                    <th width="15%">E-mail Remetente</th>
                    <th width="50%">Assunto</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in emailtemplates" :key="list.id" class="link">
                    <td @click="editTemplate(list.id)">{{ list.type_email_label }}</td>
                    <td @click="editTemplate(list.id)">{{ list.from_name }}</td>
                    <td @click="editTemplate(list.id)">{{ list.from_email }}</td>
                    <td @click="editTemplate(list.id)">{{ list.subject }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    Layout,
    PageHeader
  },
  name: 'templateemail',
  page: {
    title: 'E-mails',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      modalForm: false,
      modalRule: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      statusLabel: ['Inativo', 'Ativo'],
      submitted: false,
      title: 'E-mails',
      items: [{
        text: 'E-mails'
      },
      {
        text: 'Configurações',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapState('settings', ['setting']),
    ...mapState('emailTemplate', ['emailtemplates', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('emailTemplate', {
      getAll: 'getAll',
      changeStatus: 'changeStatus',
      deleteGamification: 'deleteSetting'
    }),
    formRules () {
      this.$refs.formRule.open()
      this.modalRule = true
    },
    newSetting () {
      this.$refs.formGamification.open('')
      this.modalForm = true
    },
    editTemplate (id) {
      this.$router.push({
        name: 'settings-email-edit',
        params: {
          id: id
        }
      })
    },
    async toggleStatus (id) {
      try {
        await this.changeStatus(id).then((res) => {
          let message = 'Sucesso: Status atualizado com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          this.updateList()
        }).catch((error) => {
          errorMessage('Alerta: ' + error.message)
        })
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    deleteSetting (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteGamification(id).then((res) => {
              successMessage('Sucesso: Objetivo excluído com sucesso.', 2500)
              this.updateList()
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage,
        domain: filter.domain || null,
        city: filter.city || null,
        district: filter.district || null,
        created_start: filter.created_start ? this.$moment(filter.created_start).format('YYYY-MM-DD') : null,
        created_end: filter.created_end ? this.$moment(filter.created_end).format('YYYY-MM-DD') : null,
        status: filter.status || null
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  },
  watch: {
    // currentPage () { this.updateList() }
    // total () { this.updateList() },
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 400px;}
.float-sm-end { float: right }
</style>
