<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form @submit.prevent="saveAccreditation()">
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12">
                  <label for="has_auto_credential">Auto credenciamento?</label>
                  <select
                    class="custom-select"
                    name="has_auto_credential"
                    id="has_auto_credential"
                    v-model="accreditation.has_auto_credential"
                    :class="{
                      'is-invalid': submitted && $v.accreditation.has_auto_credential.$error,
                    }">
                      <option value=""></option>
                      <option value="1">SIM</option>
                      <option value="0">NÃO</option>
                  </select>
                  <div
                    v-if="submitted && !$v.accreditation.has_auto_credential.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório.
                  </div>
                </div>
              </div>
              <div class="row mt-3" v-if="parseInt(accreditation.has_auto_credential) === 1">
                <div class="col-sm-12">
                  <label for="auto_checkin_after_credential">Check-in automático após auto credenciamento?</label>
                  <select
                    class="custom-select"
                    name="auto_checkin_after_credential"
                    id="auto_checkin_after_credential"
                    v-model="accreditation.auto_checkin_after_credential"
                    :class="{
                      'is-invalid': submitted && $v.accreditation.auto_checkin_after_credential.$error,
                    }">
                      <option value=""></option>
                      <option value="0">NÃO</option>
                      <option value="1">SIM</option>
                  </select>
                  <div
                    v-if="submitted && !$v.accreditation.auto_checkin_after_credential.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório.
                  </div>
                </div>
                <div class="col-sm-12 mt-3">
                  <label for="auth_accreditation">Modo de autenticação</label>
                  <select
                    class="custom-select"
                    name="auth_accreditation"
                    id="auth_accreditation"
                    v-model="accreditation.auth_accreditation"
                    :class="{
                      'is-invalid': submitted && $v.accreditation.auth_accreditation.$error,
                    }">
                      <option value=""></option>
                      <option value="1">E-mail</option>
                      <option value="2">CPF</option>
                  </select>
                  <div
                    v-if="submitted && !$v.accreditation.auth_accreditation.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Etiquetas</h4>
              <div class="row">
                <div class="col-sm-6">
                  <label for="model_label">Modelo de etiqueta</label>
                  <select
                    class="custom-select"
                    name="model_label"
                    id="model_label"
                    v-model="accreditation.model_label"
                    :class="{
                      'is-invalid': submitted && $v.accreditation.model_label.$error,
                    }">
                      <option value=""></option>
                      <option value="1">Modelo 1 (Nome)</option>
                      <option value="2">Modelo 2 (Nome + QRCode)</option>
                  </select>
                  <div
                    v-if="submitted && !$v.accreditation.model_label.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório.
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="label" :class="{'box-qrcode': parseInt(accreditation.model_label) === 2}">
                    <span class="qrcode" v-if="parseInt(accreditation.model_label) === 2">
                      <qrcode-vue  :value="qrcodeSetting.value" :size="qrcodeSetting.size" level="L" />
                      <span class="number-qrcode">H982CMX1</span>
                    </span>

                    <span
                      v-if="parseInt(accreditation.model_label) === 2"
                      :class="{'info-label-default': parseInt(accreditation.model_label) === 1,
                      'info-label-model2': parseInt(accreditation.model_label) === 2}"
                    >
                      <span
                        :class="{'name-participant-qrcode': parseInt(accreditation.model_label) === 2,
                        'name-participant-noqrcode': parseInt(accreditation.model_label) === 1}"
                      >PARTICIPANTE TESTE</span>
                    </span>

                    <span v-if="parseInt(accreditation.model_label) === 1" class="info-label-noqrcode">
                      <span
                        :class="{'name-participant-qrcode': parseInt(accreditation.model_label) === 2,
                        'name-participant-noqrcode': parseInt(accreditation.model_label) === 1}"
                      >PARTICIPANTE TESTE</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="float-right">
            <button type="buton" @click="cancelar()" class="btn btn-dark mr-4">
              Cancelar
            </button>
            <button type="submit" class="btn btn-primary mr-1">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </form>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    QrcodeVue,
    Layout,
    PageHeader
  },
  name: 'setting-accreditation',
  page: {
    title: 'Credenciamento',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      qrcodeSetting: {
        size: 125,
        level: 'L',
        value: 'YPksJ992'
      },
      loading: true,
      submitted: false,
      configEvent: false,
      accreditation: {},
      title: 'Credenciamento',
      items: [{
        text: 'Configurações gerais',
        href: '/'
      },
      {
        text: 'Credenciamento',
        active: true
      }
      ]
    }
  },
  validations () {
    let accreditation = {
      has_auto_credential: { required },
      auto_checkin_after_credential: { required },
      auth_accreditation: { required },
      model_label: { required }
    }
    if (parseInt(this.accreditation.has_auto_credential) === 1) {
      accreditation = {
        ...accreditation,
        auto_checkin_after_credential: { required },
        auth_accreditation: { required }
      }
    }
    return {
      accreditation: accreditation
    }
  },
  mounted () {
    let loader = this.$loading.show()
    this.GetAccreditation().then(() => {
      this.accreditation = this.data
      loader.hide()
    })
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('accreditation', { data: 'accreditation' })
  },
  methods: {
    ...mapActions('accreditation', {
      GetAccreditation: 'GetAccreditation',
      postRSVP: 'SaveAccreditation'
    }),
    initSetting () {
      this.configEvent = true
    },
    async saveAccreditation () {
      this.submitted = true
      this.$v.accreditation.$touch()
      if (this.$v.accreditation.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      try {
        await this.postRSVP(this.accreditation).then(() => {
          let message = 'Sucesso: Dados atualizados com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
        })
      } catch (err) {
        console.log(err)
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    }
  }
}
</script>
<style scoped>
.border-dashed {
  border: 2px dashed #ced4da !important;
  padding: 4px;
}
.box-qrcode {
  display: flex;
  padding: 5px 10px;
}
.label {
  text-align: center;
  overflow: visible;
  outline: 1px dotted;
}
.qrcode {
  float:left;
  margin-left: 10px;
  margin-top: 5px;
}
.number-qrcode {
  font-size: 18px;
  font-weight: bold;
  margin-top: -12px;
}
.info-label-default {
  text-align: left;
  margin-left: 20px;
  margin-top: 44px;
}
.info-label-model2 {
  display: block;
  text-align: left;
  margin-left: 20px;
  margin-top: 10px;
}
.name-participant-qrcode {
  display: block;
  line-height: 1.1em;
  font-size: 22px;
  font-weight: bold;
}
.name-participant-noqrcode {
  display: block;
  line-height: 1em;
  font-size: 22px;
  padding-top: 30px;
  padding-bottom: 30px;
  font-weight: bold;
}
</style>
