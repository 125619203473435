<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveForm()" autocomplete="off">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Informações do formulário</h4>
              <div class="row">
                <div class="col-sm-5">
                  <div class="form-group">
                    <label for="name">Nome do formulário</label>
                    <input
                      id="name"
                      v-model="form.name"
                      name="name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.form.name.$error
                      }"
                    />
                    <div
                      v-if="submitted && !$v.form.name.required"
                      class="invalid-feedback"
                    >
                      O campo nome é obrigatório.
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="group_id">Selecione o grupo</label>
                    <groups-select
                      :value="groupSelected"
                      @input="setGroup"
                    >
                    </groups-select>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="link_companion">Vincular formulário de acompanhante?</label>
                    <select
                      class="custom-select"
                      name="link_companion"
                      v-model="form.link_companion"
                      :class="{
                        'is-invalid': submitted && $v.form.link_companion.$error
                      }"
                    >
                      <option value="1">SIM</option>
                      <option value="0">NÃO</option>
                    </select>
                    <div
                      v-if="submitted && !$v.form.link_companion.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="parseInt(form.link_companion) === 1">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="form_companion_id">Selecione o formulário do acompanhante</label>
                    <select
                      class="custom-select"
                      name="form_companion_id"
                      v-model="form.form_companion_id"
                      :class="{
                        'is-invalid': submitted && $v.form.form_companion_id.$error
                      }"
                    >
                      <option v-for="(form,idx) in forms" :key="idx" :value="form.id">{{ form.name }}</option>
                    </select>
                    <div
                      v-if="submitted && !$v.form.form_companion_id.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Campos</h4>
              <div class="row gy-2 gx-3 align-items-center">
                <div class="col col-sm-4">
                  <div class="form-group">
                    <label for="field_name">Título do campo</label>
                    <input
                      id="field_name"
                      v-model="field.name"
                      name="field_name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': $v.field.name.$error
                      }"
                    />
                    <div
                      v-if="!$v.field.name.required"
                      class="invalid-feedback"
                    >
                      O campo nome é obrigatório.
                    </div>
                  </div>
                </div>
                <div class="col col-sm-auto">
                  <div class="form-group">
                    <label for="type_input">Tipo</label>
                    <select
                      class="custom-select"
                      name="type_input"
                      v-model="field.type_input"
                      :class="{
                        'is-invalid': $v.field.type_input.$error
                      }"
                    >
                      <option value="text">Texto</option>
                      <option value="select">Seleção</option>
                      <!-- <option value="checkbox">Caixa de seleção</option> -->
                      <!-- <option value="radio">Radio</option> -->
                      <option value="cpf">CPF</option>
                      <option value="cnpj">CNPJ</option>
                      <option value="date">Data</option>
                      <option value="phone">Telefone</option>
                      <option value="number">Número</option>
                      <option value="decimal">Número decimal</option>
                      <option value="ufs">UFs</option>
                      <option value="cep">CEP</option>
                      <option value="textarea">Texto área</option>
                      <option value="file">Arquivo</option>
                      <option value="email">E-mail</option>
                      <option value="checkbox">Termos de uso</option>
                      <option value="checkbox">Política de privacidade</option>
                    </select>
                    <div
                      v-if="!$v.field.type_input.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório.
                    </div>
                  </div>
                </div>
                <div class="col col-sm-3" v-if="showOptions">
                  <div class="form-group">
                    <label for="options">Opções</label>
                    <input
                      id="options"
                      v-model="field.options"
                      name="options"
                      type="text"
                      class="form-control"
                      placeholder="Separe as opções por virgula"
                      :class="{
                        'is-invalid': $v.field.options.$error
                      }"
                    />
                    <div
                      v-if="!$v.field.options.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório.
                    </div>
                  </div>
                </div>
                <div class="col col-sm-auto" style="margin-top:30px">
                  <b-form-checkbox
                    v-model="field.required"
                    class="mb-3"
                    >Obrigatório</b-form-checkbox
                  >
                </div>
                <div class="col col-sm-auto" style="margin-top:30px">
                  <b-form-checkbox
                    v-model="field.uniqued"
                    class="mb-3"
                    >Único</b-form-checkbox
                  >
                </div>
                <div class="col col-sm-auto mt-4">
                  <button type="button" @click="addField()" class="btn btn-success">Adicionar</button>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="example_name">Nome* <small>(campo fixo)</small></label>
                    <input
                      id="example_name"
                      type="text"
                      class="form-control gray-disabled"
                      disabled
                    />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="example_email">E-mail* <small>(campo fixo)</small></label>
                    <input
                      id="example_email"
                      type="text"
                      class="form-control gray-disabled"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="row" v-for="(field,i) in fields" :key="i">
                <div class="col-sm-10">
                  <div class="form-group">
                    <label :for="field.name">{{ field.name }}{{ field.required ? '*' : '' }}</label>
                    <input
                      id="example_name"
                      type="text"
                      class="form-control gray-disabled"
                      disabled
                    />
                  </div>
                </div>
                <div class="col-sm-auto" style="margin-top:25px">
                  <select
                    class="custom-select"
                    name="width_limit"
                    v-model="field.width_limit"
                  >
                    <option value="100">100%</option>
                    <option value="50">50%</option>
                  </select>
                </div>
                <div class="col-sm-auto" style="margin-top:25px">
                  <button type="button" @click="removeField(i)" class="btn btn-danger">
                    <i class="bx bx-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="float-right">
                <button type="buton" @click="cancelar()" class="btn btn-dark mr-4">
                  Cancelar
                </button>
                <button type="submit" class="btn btn-primary mr-1">
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import Swal from 'sweetalert2'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import GroupsSelect from '@/components/selects/groups-select'

export default {
  components: {
    Layout,
    PageHeader,
    GroupsSelect
  },
  name: 'forms',
  page: {
    title: 'Formulário',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  props: {
    idForm: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Formulário',
      items: [{
        text: 'Formulários',
        href: '/settings/forms'
      },
      {
        text: 'Novo Formulário',
        active: true
      }
      ],
      groupSelected: {},
      showOptions: false,
      loading: true,
      fullPage: true,
      submitted: false,
      form: {},
      forms: [],
      fields: [],
      field: {
        name: null,
        label: null,
        type_input: null,
        required: false,
        uniqued: false,
        width_limit: 100
      }
    }
  },
  validations () {
    let form = {
      name: { required },
      link_companion: { required },
      group_id: { required }
    }
    if (parseInt(this.form.link_companion) === 1) {
      form = { ...form, form_companion_id: { required } }
    }

    let field = {
      name: { required },
      type_input: { required }
    }
    if (this.field.type_input === 'select' || this.field.type_input === 'radio') {
      field = { ...field, options: { required } }
    }

    return {
      form,
      field
    }
  },
  mounted () {
    this.loadItem()
  },
  methods: {
    ...mapActions('form', {
      getForms: 'getAll',
      getForm: 'show',
      postData: 'save',
      deleteItemField: 'deleteField'
    }),
    getFormsCompanion () {
      let params = {
        search: null,
        status: 1,
        limit: 999,
        page: 1
      }
      this.getForms(params).then((res) => {
        this.forms = res.data
      }).catch(error => {
        console.log(error)
      })
    },
    setGroup (group) {
      this.form.group_id = group
    },
    cancelar () {
      this.$router.push({ name: 'forms' })
    },
    removeField (idx) {
      let item = this.fields[idx]
      if (item.hasOwnProperty('id')) {
        this.deleteField(item.id, idx)
        return
      }
      this.fields.splice(idx, 1)
    },
    deleteField (id, indice) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItemField({ form_id: this.$route.params.id, id: id }).then((res) => {
              successMessage('Sucesso: campo excluído com sucesso.', 2500)
              this.fields.splice(indice, 1)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    addField () {
      this.$v.field.$touch()
      if (this.$v.field.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.fields.push(this.field)
      this.$v.field.$reset()
      this.field = {
        name: null,
        label: null,
        type_input: null,
        required: false,
        uniqued: false,
        width_limit: 100
      }
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.getFormsCompanion()
        this.items[1].text = 'Editar Formulário'
        this.getForm(this.$route.params.id)
          .then(() => {
            this.form = this.data
            this.groupSelected = this.data.group_id
            if (this.data.attributes) {
              this.fields = this.data.attributes.data
            }
          }).then(() => {
            loader.hide()
          })
      }
    },
    async saveForm () {
      this.submitted = true
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      this.form.fields = this.fields
      await this.postData(this.form).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    }
  },
  computed: {
    ...mapState('form', { data: 'form' })
  },
  watch: {
    'field.type_input' (value) {
      this.showOptions = (value === 'select' || value === 'radio')
    }
  }
}
</script>
<style scoped>
.gray-disabled {
  background-color: #efefef;
}
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
