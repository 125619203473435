<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12">
          <div class="row mb-2">
            <!-- <div class="col-sm-4">
              <div class="search-box mr-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input type="text" class="form-control" placeholder="Search..." />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div> -->
            <div class="col-sm-12">
              <div class="text-sm-right">
                <button type="button" class="btn btn-dark btn-sm btn-rounded mr-2" @click="openCategory()">
                  <i class="mdi mdi-plus"></i> Categorias
                </button>
                <button type="button" class="btn btn-success btn-sm btn-rounded mr-2" @click="newPartner()">
                  <i class="mdi mdi-plus mr-1"></i> Novo Parceiro
                </button>
              </div>
            </div>
            <!-- end col-->
          </div>
          <div class="table-responsive">
            <table class="table project-list-table table-nowrap table-centered table-borderless">
              <thead>
                <tr>
                  <th scope="col" style="width: 20px"></th>
                  <th scope="col" style="width: 50px"></th>
                  <th scope="col" style="width: 150px">Categoria</th>
                  <th scope="col" width="75%">Parceiro</th>
                  <th scope="col">Status</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <draggable
                v-model="sponsors"
                :element="'tbody'"
                tag="tbody"
                :options="{animation:200, handle:'.mover'}"
                @change="updateSequence"
              >
                <tr v-for="(sponsor, i) in sponsors" :key="i" class="link">
                  <td class="mover"><i class="bx bx-move"></i></td>
                  <td class="mover">
                    <span class="badge font-size-13 badge-soft-primary p-1 badge-num">{{ sponsor.sequence }}</span>
                  </td>
                  <td @click="showSponsor(sponsor.id)">
                     {{ sponsor.category ? sponsor.category.data.name : 'Sem categoria' }}
                  </td>
                  <td @click="showSponsor(sponsor.id)">
                    <h5 class="text-truncate font-size-14">
                      <a href="javascript: void(0);" class="text-dark">{{ sponsor.name }}</a>
                    </h5>
                  </td>
                  <td @click="showSponsor(sponsor.id)">
                    <span class="badge badge-pill font-size-12" :class="{
                        'badge-soft-success': `${parseInt(sponsor.status)}` === '1',
                        'badge-soft-danger': `${parseInt(sponsor.status)}` === '0',
                      }">{{ statusLabel[sponsor.status] }}</span>
                  </td>
                  <td>
                    <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                        <template v-slot:button-content>
                          <i class="mdi mdi-dots-horizontal font-size-18"></i>
                        </template>
                        <b-dropdown-item @click="editPartner(sponsor.id)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="toggleStatus(sponsor.id)">
                          <i class="fas fa-circle mr-1" :class="{ 'text-danger': parseInt(sponsor.status) === 1, 'text-success': parseInt(sponsor.status) === 0 }"></i> {{ sponsor.status ? 'Inativar' : 'Ativar' }}</b-dropdown-item>
                        <b-dropdown-item @click="deletePartner(sponsor.id)">
                          <i class="fas fa-trash-alt text-danger mr-1"></i> Excluir
                        </b-dropdown-item>
                      </b-dropdown>
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <category v-model="modalCategory" ref="modalCategory"></category>
  </Layout>
</template>
<script>
import draggable from 'vuedraggable'
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import Category from './Category'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  components: {
    draggable,
    Layout,
    PageHeader,
    Category
  },
  name: 'Patrocinadores',
  page: {
    title: 'Patrocinadores',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      params: {},
      totalRows: 1,
      modalCategory: false,
      currentPage: 1,
      perPage: 20,
      statusLabel: ['Inativo', 'Ativo'],
      submitted: false,
      title: 'Patrocinadores',
      items: [{
        text: 'Patrocinadores'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapGetters('sponsors', { data: 'SPONSORS' }),
    ...mapState('sponsors', ['pagination']),
    rows () {
      return this.pagination.total
    },
    sponsors: {
      get () {
        return this.data
      },
      set (value) {
        let loader = this.$loading.show()
        this.updateSequence({ itens: value }).then(res => {
          loader.hide()
          successMessage('Sucesso: Ordem atualizada.', 2500)
        })
      }
    }
  },
  methods: {
    ...mapActions('sponsors', {
      getList: 'list',
      changeStatus: 'changeStatus',
      deleteItem: 'destroy',
      updateSequence: 'orderList'
    }),
    showSponsor (id) {
      this.$router.push({
        name: 'site-sponsor-show',
        params: {
          id: id
        }
      })
    },
    openCategory () {
      this.$refs.modalCategory.open()
    },
    newPartner () {
      this.$router.push({
        name: 'site-sponsor-new'
      })
    },
    editPartner (id) {
      this.$router.push({
        name: 'sponsor-edit',
        params: {
          id: id
        }
      })
    },
    async toggleStatus (id) {
      try {
        await this.changeStatus(id)
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    deletePartner (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteItem(id).then((res) => {
              successMessage('Sucesso: Sala excluído com sucesso.', 2500)
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
            this.updateList()
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    updateList () {
      let loader = this.$loading.show()
      this.getList({ limit: this.perPage, include: 'category', page: this.currentPage }).then(() => {
        loader.hide()
      })
    }
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.avatar-thumb { height: 4rem; width: 6rem;}
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 400px;}
</style>
