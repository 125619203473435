var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.title,"size":"lg","no-enforce-focus":true,"no-close-on-backdrop":true},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancelar")]),_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"},on:{"click":function($event){return _vm.saveTicket()}}},[_vm._v("Salvar")])]},proxy:true}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('loading',{attrs:{"active":_vm.loading,"color":"#556ee6","loader":"dots","can-cancel":false,"is-full-page":false},on:{"update:active":function($event){_vm.loading=$event}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.saveTicket()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-8"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nome do ingresso")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ticket.name),expression:"ticket.name"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.ticket.name.$error
                },attrs:{"id":"name","name":"name","type":"text"},domProps:{"value":(_vm.ticket.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ticket, "name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.ticket.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo nome é obrigatório. ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"amount_ticket"}},[_vm._v("Valor do Ingresso")]),_c('money',_vm._b({staticClass:"form-control text-right",class:{
                  'is-invalid': _vm.submitted && _vm.$v.ticket.amount_ticket.$error
                },model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}},'money',_vm.money,false)),(_vm.submitted && !_vm.$v.ticket.amount_ticket.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo nome é obrigatório. ")]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-8"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Descrição")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ticket.description),expression:"ticket.description"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.ticket.description.$error
                },attrs:{"id":"description","name":"description","type":"text"},domProps:{"value":(_vm.ticket.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ticket, "description", $event.target.value)}}}),(_vm.submitted && !_vm.$v.ticket.description.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"type"}},[_vm._v("Tipo")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.ticket.type_ticket),expression:"ticket.type_ticket"}],staticClass:"custom-select",class:{
                  'is-invalid': _vm.submitted && _vm.$v.ticket.type_ticket.$error
                },attrs:{"name":"type_ticket"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.ticket, "type_ticket", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}}),_c('option',{attrs:{"value":"0"}},[_vm._v("PRESENCIAL")]),_c('option',{attrs:{"value":"1"}},[_vm._v("ONLINE")])]),(_vm.submitted && !_vm.$v.ticket.type_ticket.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório. ")]):_vm._e()])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }